import { bin_location as binLocationAPI } from '../api'
import {
  ADD_BIN_LOCATION_BUSINESS,
  REMOVE_BIN_LOCATION_BUSINESS,
  UPDATE_BIN_LOCATION_LAT_LNG,
  CLEAR_BIN_LOCATION_FORM,
} from '../constants/bin_location.constants'
export const bin_location = {
  searchList(query) {
    return dispatch => {
      return dispatch(binLocationAPI.searchList(query))
    }
  },
  fetch(pagination, rowsPerPage, query) {
    return dispatch => {
      return dispatch(binLocationAPI.list(pagination, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(binLocationAPI.get(id))
    }
  },
  getBinsForBinLocations(id) {
    return dispatch => {
      return dispatch(binLocationAPI.getBinsForBinLocations(id))
    }
  },
  count(query) {
    return dispatch => {
      return dispatch(binLocationAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(binLocationAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(binLocationAPI.remove(id))
    }
  },
  addBusiness(business) {
    return dispatch => {
      return dispatch({
        type: ADD_BIN_LOCATION_BUSINESS,
        payload: business,
      })
    }
  },
  removeBusiness(id) {
    return dispatch => {
      return dispatch({
        type: REMOVE_BIN_LOCATION_BUSINESS,
        payload: id,
      })
    }
  },
  updateLatLng(lngLat) {
    return dispatch => {
      return dispatch({
        type: UPDATE_BIN_LOCATION_LAT_LNG,
        payload: lngLat,
      })
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_BIN_LOCATION_FORM,
      })
    }
  },
}
