export default {
  typography: {
    fontFamily: [
      'Open Sans',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#ff4400',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#198ffb'
    }
  }
}
