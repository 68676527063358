import {
  FETCH_BINBAG_ROLL_FAILED,
  FETCH_BINBAG_ROLL_SUCCEED,
  FETCH_BINBAG_ROLL_PROCESSING,
  GET_BINBAG_ROLL_FAILED,
  GET_BINBAG_ROLL_SUCCEED,
  GET_BINBAG_ROLL_PROCESSING,
  SAVE_BINBAG_ROLL_FAILED,
  SAVE_BINBAG_ROLL_SUCCEED,
  SAVE_BINBAG_ROLL_PROCESSING,
  DELETE_BINBAG_ROLL_FAILED,
  DELETE_BINBAG_ROLL_SUCCEED,
  DELETE_BINBAG_ROLL_PROCESSING,
} from '../../constants'
import { db } from '../firebase'

const dbCollection = 'binbag_rolls'

const list = (page = 0, rowsPerPage = 10, searchText = '') => {
  return async dispatch => {
    dispatch({
      type: FETCH_BINBAG_ROLL_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      const docRefs = await collectionRef.get()
      const list = []
      let i = 0
      for (const binbagRollSnap of docRefs.docs) {
        const binbagRollData = binbagRollSnap.data()
        list[i] = {
          [binbagRollSnap.id]: {
            ...binbagRollData,
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_BINBAG_ROLL_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_BINBAG_ROLL_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_BINBAG_ROLL_PROCESSING,
    })
    const collectionRef = db.collection(dbCollection)
    const docRef = collectionRef.doc(id)
    const binbagRollRef = await docRef.get()
    if (binbagRollRef.exists) {
      const binbagRollData = binbagRollRef.data()
      return dispatch({
        type: GET_BINBAG_ROLL_SUCCEED,
        payload: {
          record: {
            ...binbagRollData,
            id: binbagRollRef.id,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BINBAG_ROLL_FAILED,
        payload: {
          error: `No such collection request: ${id}`,
        },
      })
    }
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_BINBAG_ROLL_PROCESSING,
    })

    const data = {
      ...values,
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(data, { merge: true })
      } else {
        await db.collection(dbCollection).add(data)
      }
      return dispatch({
        type: SAVE_BINBAG_ROLL_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: SAVE_BINBAG_ROLL_FAILED,
        payload: {
          error: 'Error: Saving quote request field',
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_BINBAG_ROLL_PROCESSING,
    })
    try {
      const promises = []
      for (const id of ids) {
        promises.push(
          db
            .collection(dbCollection)
            .doc(id)
            .delete()
        )
      }
      await Promise.all(promises)
      return dispatch({
        type: DELETE_BINBAG_ROLL_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_BINBAG_ROLL_FAILED,
      })
    }
  }
}

export const binbag_roll = {
  list,
  get,
  save,
  remove,
}
