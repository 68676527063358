import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  SubmissionError,
  reduxForm,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  Typography,
  withStyles,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import CustomSnackbar from '../CustomSnackbar'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'
import MapField from '../Map'

import { bin_location } from '../../actions'

import { overlay, loadingSpinner } from '../../variables/styles'
import { business } from '../../api'
import { defaultLatLng } from '../../constants'
import DownshiftMultiple from '../CustomSelect/DownshiftMultiple'
import CustomTextField from '../CustomTextField'

export const fields = ['id', 'businesses']
const baseUrl = '/bin-location'
const form = 'bin_location'

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  if (values.loc) {
    const { latitude, longitude } = values.loc
    if (latitude) {
      if (parseFloat(latitude) > 90 || parseFloat(latitude) < -90) {
        errors.loc = 'Latitude must be between -90 and 90'
      }
      if (longitude) {
        if (parseFloat(longitude) > 180 || parseFloat(longitude) < -180) {
          errors.loc = 'Longitude must be between -180 and 180'
        }
      }
    }
  }
  if (values.businesses && values.businesses.length === 0) {
    errors.businesses = 'You must select a business'
  }

  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving bin location' })
  }
}

class BinLocationEdit extends Component {
  state = {
    dialogExitWithoutSavingOpen: false,
    popupInfo: null,
    viewport: {
      width: '100%',
      height: 250,
      ...defaultLatLng,
      zoom: 12,
    },
    ...defaultLatLng,
  }

  static propTypes = {
    ...reduxFormPropTypes,
    record: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    fetchingBins: PropTypes.bool.isRequired,
    fetchedBins: PropTypes.bool.isRequired,
    binList: PropTypes.array,
    saving: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
    clearForm: PropTypes.func.isRequired,
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.get(this.props.match.params.id)
      await this.props.getBinsForBinLocations(this.props.match.params.id)
      this.setState({
        latitude: this.props.record.loc.latitude,
        longitude: this.props.record.loc.longitude,
        viewport: {
          ...this.state.viewport,
          latitude: this.props.record.loc.latitude,
          longitude: this.props.record.loc.longitude,
        },
      })
    } else {
      this.props.clearForm()
    }
  }
  getBusinessSuggestions = async value => {
    try {
      const results = await business.search(value)
      return results.hits.length > 0
        ? results.hits.map(result => ({
            name: result.name,
            value: result.objectID,
          }))
        : []
    } catch (err) {
      console.error(err)
      return []
    }
  }

  handleClickBusinessChip = id => {
    this.props.history.push(`/business/edit/${id}`)
  }

  draggingMarker = event => {
    this.props.dispatch(bin_location.updateLatLng(event.lngLat))
  }

  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }

  backToList = () => {
    this.props.history.push(baseUrl)
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const {
      handleSubmit,
      submitFailed,
      invalid,
      pristine,
      saving,
      saved,
      error,
      submitting,
      classes,
      fetching,
      fetchingBins,
      fetchedBins,
      binList,
      match,
      history,
    } = this.props
    const title = match.params.id ? 'Edit Bin Location' : 'Add new'
    return (
      <>
        <Fade in={fetching || submitting}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <form onSubmit={handleSubmit}>
                <CardHeader
                  title={title}
                  action={
                    <IconButton onClick={this.handleBackButton}>
                      <ArrowBackIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Grid container spacing={24} direction="column">
                    <Grid item>
                      <Field
                        name="name"
                        component={CustomTextField}
                        label="Name"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">Address</Typography>
                      <Field
                        name="address.line_1"
                        component={CustomTextField}
                        label="Line 1"
                      />
                      <Field
                        name="address.line_2"
                        component={CustomTextField}
                        label="Line 2"
                      />
                      <Field
                        name="address.line_3"
                        component={CustomTextField}
                        label="Line 3"
                      />
                      <Field
                        name="address.post_town"
                        component={CustomTextField}
                        label="Post town"
                      />
                      <Field
                        name="address.postcode"
                        component={CustomTextField}
                        label="Postcode"
                      />
                    </Grid>
                    {this.props.match.params.id && (
                      <Grid item>
                        <Typography variant="subtitle1">Bins</Typography>
                        {fetchingBins && '...'}
                        {fetchedBins && binList && binList.length > 0
                          ? binList.map(bin => (
                              <>
                                <Button
                                  key={bin.value}
                                  onClick={() =>
                                    history.push(`/bin/edit/${bin.value}`)
                                  }
                                >
                                  {bin.name}
                                </Button>{' '}
                              </>
                            ))
                          : fetchedBins
                          ? 'No bins found'
                          : ''}
                      </Grid>
                    )}
                    <Grid item>
                      <FieldArray
                        name="businesses"
                        component={DownshiftMultiple}
                        label="Businesses"
                        placeholder="Select some businesses"
                        getSuggestions={this.getBusinessSuggestions}
                        handleClickChip={this.handleClickBusinessChip}
                        gutterBottom
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">Location</Typography>
                      <Field name="loc" component={MapField} />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={pristine || fetching || invalid || saving}
                    className={classes.button}
                  >
                    SAVE
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
        </Grid>

        <CustomSnackbar
          variant={submitFailed ? 'error' : 'success'}
          open={saved || Boolean(error)}
          message={error || 'Saved'}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </>
    )
  }
}

const mapStateToProps = ({ bin_location }) => ({
  ...bin_location,
  fields,
  initialValues: bin_location.record,
})

const mapDispatchToProps = { ...bin_location }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form,
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(BinLocationEdit)
  )
)
