export const FETCH_COMMENT_PROCESSING = 'FETCH_COMMENT_PROCESSING'
export const FETCH_COMMENT_SUCCEED = 'FETCH_COMMENT_SUCCEED'
export const FETCH_COMMENT_FAILED = 'FETCH_COMMENT_FAILED'
export const GET_COMMENT_PROCESSING = 'GET_COMMENT_PROCESSING'
export const GET_COMMENT_SUCCEED = 'GET_COMMENT_SUCCEED'
export const GET_COMMENT_FAILED = 'GET_COMMENT_FAILED'
export const SAVE_COMMENT_PROCESSING = 'SAVE_COMMENT_PROCESSING'
export const SAVE_COMMENT_SUCCEED = 'SAVE_COMMENT_SUCCEED'
export const SAVE_COMMENT_FAILED = 'SAVE_COMMENT_FAILED'
export const DELETE_COMMENT_PROCESSING = 'DELETE_COMMENT_PROCESSING'
export const DELETE_COMMENT_SUCCEED = 'DELETE_COMMENT_SUCCEED'
export const DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED'
export const CLEAR_COMMENT_FORM = 'CLEAR_COMMENT_FORM'
