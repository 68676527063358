import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Paper, withStyles } from '@material-ui/core'
import { deburr } from 'lodash'
import DownshiftInput from './DownshiftInput'
import DownshiftSuggestion from './DownshiftSuggestion'

import Downshift from 'downshift'

class DownshiftMultiple extends React.Component {
  state = {
    selectedItems: [],
    suggestions: [],
  }

  static propTypes = {
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    getSuggestions: PropTypes.func.isRequired,
    itemToString: PropTypes.func,
    handleClickChip: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    chipAvatar: PropTypes.element,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    itemToString: i => {
      return i == null ? '' : i.name ? i.name : ''
    },
  }

  componentDidMount() {
    const selectedItems = this.props.fields.getAll() || []
    this.setState({ selectedItems })
  }

  handleInputValueChanged = async value => {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) return []
    const suggestions = await this.props.getSuggestions(inputValue)
    this.setState({
      suggestions,
    })
  }

  handleChange = (selectedItem, stateAndHelpers) => {
    stateAndHelpers.setState({ inputValue: '' })
    this.props.fields.push(selectedItem)
  }

  handleDelete = item => {
    this.props.fields.getAll().forEach(
      (field, i) => field.value === item.value && this.props.fields.remove(i)
    )
  }

  render() {
    const {
      fields,
      label,
      placeholder,
      chipAvatar,
      handleClickChip,
      itemToString,
      meta,
      classes,
    } = this.props

    const items = fields.getAll() || []

    return (
      <Downshift
        id="downshift-multiple"
        onChange={this.handleChange}
        initialInputValue={''}
        onInputValueChange={this.handleInputValueChanged}
        itemToString={itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            <DownshiftInput
              fullWidth={true}
              classes={classes}
              InputProps={getInputProps({
                startAdornment: items.map((item, index) => (
                  <Chip
                    avatar={chipAvatar}
                    key={index}
                    color="primary"
                    tabIndex={-1}
                    label={item.name}
                    className={classes.chip}
                    onClick={() => handleClickChip(item.value)}
                    onDelete={() => this.handleDelete(item)}
                  />
                )),
                label,
                meta,
                placeholder,
              })}
              margin={'normal'}
              meta={meta}
              label={label}
            />
            {isOpen ? (
              <Paper className={classes.paper} square>
                {this.state.suggestions.map((suggestion, index) => (
                  <DownshiftSuggestion
                    key={index}
                    suggestion={suggestion}
                    index={index}
                    itemProps={getItemProps({
                      item: suggestion,
                      key: index,
                    })}
                    highlightedIndex={highlightedIndex}
                  />
                ))}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  chip: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
})

export default withStyles(styles)(DownshiftMultiple)
