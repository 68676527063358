import { binbag_request as binbag_requestAPI } from '../api'
import { CLEAR_FORM } from '../constants/binbag_request.constants'
export const binbag_request = {
  fetch(pagination, rowsPerPage, orderBy, filterBy = {}) {
    return dispatch => {
      return dispatch(
        binbag_requestAPI.fetch(pagination, rowsPerPage, orderBy, filterBy)
      )
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(binbag_requestAPI.get(id))
    }
  },
  count(query = '') {
    return dispatch => {
      return dispatch(binbag_requestAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(binbag_requestAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(binbag_requestAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_FORM,
      })
    }
  },
}
