import { binbag_roll as binbag_rollAPI } from '../api'
import { CLEAR_FORM } from '../constants/binbag_roll.constants'
export const binbag_roll = {
  list(pagination, rowsPerPage, query) {
    return dispatch => {
      return dispatch(binbag_rollAPI.list(pagination, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(binbag_rollAPI.get(id))
    }
  },
  count(query = '') {
    return dispatch => {
      return dispatch(binbag_rollAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(binbag_rollAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(binbag_rollAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_FORM,
      })
    }
  },
}
