// ##############################
// // // Sidebar styles
// #############################

import {
	drawerWidth,
	transition,
	boxShadow,
	defaultFont,
	primaryColor,
	primaryBoxShadow,
	// infoColor,
	successColor,
	warningColor,
	dangerColor,
	typoHeaderColor
} from '../styles'

const sidebarStyle = (theme) => ({
	drawerPaper: {
		backgroundColor: typoHeaderColor,
		border: 'none',
		position: 'fixed',
		top: '0',
		bottom: '0',
		left: '0',
		zIndex: '1',
		// overflow: 'auto',
		...boxShadow,
		width: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			position: 'fixed',
			height: '100%'
		},
		[theme.breakpoints.down('sm')]: {
			width: drawerWidth,
			...boxShadow,
			position: 'fixed',
			display: 'block',
			top: '0',
			height: '100vh',
			right: '0',
			left: 'auto',
			zIndex: '1032',
			visibility: 'visible',
			overflowY: 'visible',
			borderTop: 'none',
			textAlign: 'left',
			paddingRight: '0px',
			paddingLeft: '0',
			transform: `translate3d(${drawerWidth}px, 0, 0)`,
			...transition
		}
	},
	logo: {
		position: 'relative',
		padding: '20px 0',
		zIndex: '4',
		'&:after': {
			content: '""',
			position: 'absolute',
			bottom: '0',
			height: '0.5px',
			right: '0',
			width: '100%',
			backgroundColor: 'rgba(255,255,255, 0.20)'
		}
	},
	logoLink: {
		...defaultFont,
		textTransform: 'uppercase',
		padding: '5px 0',
		display: 'block',
		fontSize: '18px',
		textAlign: 'center',
		fontWeight: '400',
		lineHeight: '30px',
		textDecoration: 'none',
		backgroundColor: 'transparent',
		'&,&:hover': {
			color: '#FFFFFF'
		}
	},
	logoImage: {
		display: 'block'
	},
	img: {
		width: '98px',
		verticalAlign: 'middle',
		border: '0'
	},
	background: {
		position: 'absolute',
		zIndex: '1',
		height: '100%',
		width: '100%',
		display: 'block',
		top: '0',
		left: '0',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		'&:after': {
			position: 'absolute',
			zIndex: '3',
			width: '100%',
			height: '100%',
			content: '""',
			display: 'block',
			background: '#000',
			opacity: '.8'
		}
	},
	list: {
		marginTop: '20px',
		paddingLeft: '0',
		paddingTop: '0',
		paddingBottom: '0',
		marginBottom: '0',
		listStyle: 'none'
	},
	expandable: {
		backgroundColor: 'transparent',
    boxShadow: 'inherit',
    margin: "0!important",
    '&.break': {
			paddingBottom: '15px!important',
			marginBottom: '15px!important',
			'&:after': {
				content: '""',
				position: 'absolute',
				bottom: '0',
				height: '0.5px',
				right: '15px',
				width: 'calc(100% - 30px)',
				backgroundColor: 'rgba(255,255,255, 0.20)'
			}
		}    
	},
	expandablePanelSummary: {
    padding: "0!important",
    minHeight: "inherit!important"
  },
  expandableContentSummary: {
    margin: "0!important",
    display: "block"
  },
  expandIcon: {
    color: "#FFF",
    right: 0,
  },
  expandablePanelDetails: {
    padding: "0 24px",
    display: "block"
  },
	item: {
		position: 'relative',
		display: 'block',
    textDecoration: 'none',
    paddingRight: "0!important",
		'&.break': {
			paddingBottom: '15px',
			marginBottom: '15px',
			'&:after': {
				content: '""',
				position: 'absolute',
				bottom: '0',
				height: '0.5px',
				right: '15px',
				width: 'calc(100% - 30px)',
				backgroundColor: 'rgba(255,255,255, 0.20)'
			}
		}
	},
	itemLink: {
		width: 'auto',
		transition: 'all 300ms linear',
		margin: '0',
		borderRadius: '0px',
		position: 'relative',
		display: 'block',
		padding: '8px 20px',
		backgroundColor: 'transparent',
		...defaultFont
	},
	itemIcon: {
		width: '14px',
		height: '20px',
		float: 'left',
		marginRight: '30px',
		textAlign: 'center',
		verticalAlign: 'middle',
		color: 'rgba(255, 255, 255, 0.9)'
	},
	itemText: {
		...defaultFont,
		margin: '0',
		lineHeight: '22px',
		fontSize: '13px',
		color: '#FFFFFF'
	},
	whiteFont: {
		color: '#FFFFFF'
	},
	purple: {
		backgroundColor: primaryColor,
		...primaryBoxShadow,
		'&:hover': {
			backgroundColor: primaryColor,
			...primaryBoxShadow
		}
	},
	blue: {
		backgroundColor: 'rgba(255,255,255, 0.15)',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255, 0.15)'
		}
	},
	green: {
		backgroundColor: successColor,
		boxShadow:
			'0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
		'&:hover': {
			backgroundColor: successColor,
			boxShadow:
				'0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)'
		}
	},
	orange: {
		backgroundColor: warningColor,
		boxShadow:
			'0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
		'&:hover': {
			backgroundColor: warningColor,
			boxShadow:
				'0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)'
		}
	},
	red: {
		backgroundColor: dangerColor,
		boxShadow:
			'0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
		'&:hover': {
			backgroundColor: dangerColor,
			boxShadow:
				'0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)'
		}
	},
	sidebarWrapper: {
		position: 'relative',
		height: 'calc(100vh - 75px)',
		overflow: 'auto',
		width: '260px',
		zIndex: '4',
		overflowScrolling: 'touch'
	}
})

export default sidebarStyle
