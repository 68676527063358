import { quote_request as quote_requestAPI } from '../api'
import { CLEAR_FORM } from '../constants/quote_request.constants'
export const quote_request = {
  fetch(pagination, rowsPerPage, orderBy, filterBy) {
    return dispatch => {
      return dispatch(
        quote_requestAPI.list(pagination, rowsPerPage, orderBy, filterBy)
      )
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(quote_requestAPI.get(id))
    }
  },
  count(query = '') {
    return dispatch => {
      return dispatch(quote_requestAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(quote_requestAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(quote_requestAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_FORM,
      })
    }
  },
}
