import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  withStyles
} from '@material-ui/core'

class DialogCloseWithoutSaving extends Component {
  render() {
    const {
      dialogExitWithoutSavingOpen,
      handleExitWithSaving,
      handleExitWithoutSaving,
      handleDismissExitWithoutSaving
    } = this.props
    return (
      <Dialog
        open={dialogExitWithoutSavingOpen}
        onClose={handleDismissExitWithoutSaving}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Exit without saving'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes, please save before continuing
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExitWithSaving} color="primary">
            Save
          </Button>
          <Button
            onClick={handleExitWithoutSaving}
            color="primary"
            autoFocus
          >
            Exit without saving
          </Button>
          <Button
            onClick={handleDismissExitWithoutSaving}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogCloseWithoutSaving.propTypes = {
  dialogExitWithoutSavingOpen: PropTypes.bool.isRequired,
  handleExitWithSaving: PropTypes.func.isRequired,
  handleExitWithoutSaving: PropTypes.func.isRequired,
  handleDismissExitWithoutSaving : PropTypes.func.isRequired 
}

const styles = theme => ({})

export default withStyles(styles)(DialogCloseWithoutSaving)
