import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { debounce } from 'lodash'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  MergeType as MergeTypeIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'

import { business } from '../../actions'
import CustomTable from '../Table/CustomTable'
import { selects } from '../../constants'

const baseUrl = '/business'

class BusinessList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    orderBy: ['name', 'asc'],
    query: '',
    filterBinGenus: '',
    filterBinGenera: [],
    filterBy: {},
    fields: [
      {
        id: 'name',
        label: 'Name',
        enableSort: true,
      },
      {
        id: 'address',
        label: 'Address',
        enableSort: true,
      },
      {
        id: 'business_types',
        label: 'Business types',
        select: {
          valueField: 'value',
          textField: 'text',
          data: selects.business_types,
          handleChange: value => {
            this.setState(
              {
                ...this.state,
                filterBy: {
                  ...this.state.filterBy,
                  business_types: value,
                },
              },
              () => this._getAll()
            )
          },
        },
      },
    ],
  }
  static propTypes = {
    total: PropTypes.number,
    list: PropTypes.array,
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    record: PropTypes.object,
    searchText: PropTypes.string,
    errorMessage: PropTypes.string,
    deleted: PropTypes.bool,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    location: PropTypes.object.isRequired,
  }
  componentDidMount() {
    const query = qs.parse(this.props.location.search)
    this.setState(
      {
        query: query.searchText || '',
        page: query.page || 0,
      },
      () => {
        this._getAll()
      }
    )
  }
  _getAll(direction = 0) {
    let pagination = {}
    if (direction !== 0 && this.props.list[0]) {
      pagination = {
        direction,
        id:
          direction === -1
            ? Object.keys(this.props.list[0])[0]
            : Object.keys(this.props.list[this.props.list.length - 1])[0],
      }
    }
    this.props.dispatch(
      business.list(
        pagination,
        this.state.rowsPerPage,
        this.state.orderBy,
        this.state.filterBy
      )
    )
    const search = qs.stringify({
      orderBy: this.state.orderBy,
      filterBy: this.state.filterBy,
    })
    this.props.history.push({ search })
  }
  handleChangePage = page => {
    const direction = page - this.state.page
    this.setState({ page: page }, () => {
      this._getAll(direction)
    })
  }
  handleSearch = debounce(async query => {
    await this.props.dispatch(business.searchList(query))
  }, 1000)
  handleRequestSort = (property, order) => {
    const orderBy = [property, order]
    this.setState({ orderBy }, () => {
      this._getAll()
    })
  }
  handleSelectChange = event => {
    this.setState({
      filterBinGenus: event.target.value,
    })
    this.handleSearch(this.state.query)
  }
  handleMerge = (primary, selected) => {
    this.props.dispatch(business.mergeBins(primary, selected))
  }
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getAll()
    })
  }
  handleDeleteAction = async id => {
    await this.props.dispatch(business.remove(id))
    this._getAll()
  }
  render() {
    const { list, total, fetching, fetched, history } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push(`${baseUrl}/add`)}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Businesses'}
          total={total}
          fetching={fetching}
          fetched={fetched}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath={`${baseUrl}/edit/`}
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: `${baseUrl}/edit/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'merge',
              icon: <MergeTypeIcon />,
              path: `${baseUrl}/merge/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.business.total,
  list: state.business.list,
  fetching: state.business.fetching,
  fetched: state.business.fetched,
  deleted: state.business.deleted,
  errorMessage: state.business.errorMessage,
  mergingBins: state.business.mergingBins,
  mergingBinsSuccess: state.business.mergingBinsSuccess,
  mergingBinsFailure: state.business.mergingBinsFailure,
})

export default connect(mapStateToProps)(BusinessList)
