import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import { auth } from '../../actions'
import headerLinksStyle from '../../variables/styles/headerLinksStyle'
class HeaderLinks extends React.Component {
  state = {
    open: false,
  }
  handleClick = () => {
    this.setState({ open: !this.state.open })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  signOut = () => {
    this.props.dispatch(auth.logout())
  }
  render() {
    // const { open } = this.state
    return (
      <div>
        {/* <CustomInput
          formControlProps={{
            className: classes.top + ' ' + classes.search,
          }}
          inputProps={{
            placeholder: 'Search',
            inputProps: {
              'aria-label': 'Search',
            },
          }}
        /> */}
      </div>
    )
  }
}

HeaderLinks.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.object,
}

const mapStateToProps = state => ({})

export default withStyles(headerLinksStyle)(
  connect(mapStateToProps)(HeaderLinks)
)
