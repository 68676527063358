import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'

import mapStyle from '../../variables/styles/mapStyle'
import { ItemPin } from './ItemPin'
import { InfoPopup } from './InfoPopup'
import { defaultLatLng } from '../../constants'

class MapField extends Component {
  state = {
    loaded: false,
    popupInfo: null,
    viewport: {
      width: '100%',
      height: 250,
      ...defaultLatLng,
      zoom: 12,
    },
  }

  static defaultProps = {
    enablePopup: false,
  }

  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    enablePopup: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    if ('geolocation' in navigator) {
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          currentPositionLatitude: position.coords.latitude,
          currentPositionLongitude: position.coords.longitude,
          viewport: {
            ...this.state.viewport,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
      })
    }
  }

  componentDidUpdate() {
    // hack to make sure that the viewport is set in the right place
    // on lazy loading of the values
  }

  handleChange = event => {
    const [longitude, latitude] = event.lngLat
    this.props.input.onChange({ latitude, longitude })
  }

  _renderPopup = () => {
    const { popupInfo } = this.state
    const { latitude, longitude } = this.props.input.value

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={longitude}
          latitude={latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <InfoPopup info={popupInfo} />
        </Popup>
      )
    )
  }

  _onMapLoad = () => {
    if (this.props.input.value) {
      const { latitude, longitude } = this.props.input.value
      if (
        !this.state.loaded &&
        latitude !== defaultLatLng.latitude &&
        longitude !== defaultLatLng.longitude
      ) {
        this.setState({
          loaded: true,
          viewport: {
            ...this.state.viewport,
            latitude: latitude,
            longitude: longitude,
          },
        })
      }
    }
  }

  render() {
    const { input, label, enablePopup } = this.props
    return (
      <ReactMapGL
        style={mapStyle.map}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        width={this.state.viewport.width}
        height={this.state.viewport.height}
        latitude={this.state.viewport.latitude}
        longitude={this.state.viewport.longitude}
        zoom={this.state.viewport.zoom}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onViewportChange={viewport => {
          this.setState({
            viewport,
          })
        }}
        onLoad={this._onMapLoad}
      >
        <Marker
          latitude={input.value.latitude || 0}
          longitude={input.value.longitude || 0}
          draggable={true}
          onDragEnd={event => this.handleChange(event)}
        >
          <ItemPin
            size={20}
            onClick={() => this.setState({ popupInfo: label })}
          />
          {enablePopup ? this._renderPopup() : ''}
        </Marker>
      </ReactMapGL>
    )
  }
}

export * from './ItemPin'
export * from './InfoPopup'

export default MapField
