import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'

import { user } from '../../actions'
import CustomTable from '../Table/CustomTable'
import { selects } from '../../constants'

const baseUrl = '/user'

class User extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    orderBy: ['displayName', 'asc'],
    filterBy: {},
    fields: [
      { id: 'email', label: 'Email' },
      { id: 'displayName', label: 'Name' },
      {
        id: 'role',
        label: 'Role',
        select: {
          valueField: 'value',
          textField: 'text',
          data: selects.roles,
          handleChange: value => {
            this.setState(
              {
                ...this.state,
                filterBy: {
                  ...this.state.filterBy,
                  role: value,
                },
              },
              () => this._getAll()
            )
          },
        },
      },
    ],
  }

  static propTypes = {
    total: PropTypes.number,
    list: PropTypes.array,
    fetch: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    deleted: PropTypes.bool,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
  }

  componentDidMount() {
    this._getAll()
  }
  _getAll = async (direction = 0) => {
    let pagination = {}
    if (direction !== 0 && this.props.list[0]) {
      pagination = {
      direction,
        id:
          direction === -1
            ? Object.keys(this.props.list[0])[0]
            : Object.keys(this.props.list[this.props.list.length - 1])[0],
      }
    }
    await this.props.fetch(
      pagination,
      this.state.rowsPerPage,
      this.state.orderBy,
      this.state.filterBy
    )
    let searchParams = {}
    if (this.state.orderBy) searchParams.orderBy = this.state.orderBy
    if (this.state.filterBy) searchParams.filterBy = this.state.filterBy
    const search = qs.stringify(searchParams)
    this.props.history.push({ search })
  }
  handleChangePage = page => {
    const direction = page - this.state.page
    this.setState({ page: page }, () => {
      this._getAll(direction)
    })
  }
  handleRoleSelectChange = value => {
    this.setState(
      {
        ...this.state,
        filterBy: {
          ...this.state.filterBy,
          role: value,
        },
      },
      this._getAll
    )
  }
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getAll()
    })
  }
  handleDeleteAction = async id => {
    await this.props.remove(id)
    this._getAll()
  }
  handleRequestSort = (property, order) => {
    const orderBy = [property, order]
    this.setState({ orderBy }, () => {
      this._getAll()
    })
  }
  render() {
    const { list, total, fetching, fetched, history } = this.props
    return (
      <div>
        <CustomTable
          title="Users"
          total={total}
          fetching={fetching}
          fetched={fetched}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath={`${baseUrl}/edit/`}
          handleRequestSort={this.handleRequestSort}
        />
      </div>
    )
  }
}

const mapDispatchToProps = { ...user }

const mapStateToProps = state => ({
  total: state.user.total,
  list: state.user.list,
  fetching: state.quote_request.fetching,
  fetched: state.quote_request.fetched,
  deleted: state.user.deleted,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User)
