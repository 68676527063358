export const COUNT_QUOTE_REQUEST_PROCESSING = 'COUNT_QUOTE_REQUEST_PROCESSING'
export const COUNT_QUOTE_REQUEST_SUCCEED = 'COUNT_QUOTE_REQUEST_SUCCEED'
export const COUNT_QUOTE_REQUEST_FAILED = 'COUNT_QUOTE_REQUEST_FAILED'
export const FETCH_QUOTE_REQUEST_PROCESSING = 'FETCH_QUOTE_REQUEST_PROCESSING'
export const FETCH_QUOTE_REQUEST_SUCCEED = 'FETCH_QUOTE_REQUEST_SUCCEED'
export const FETCH_QUOTE_REQUEST_FAILED = 'FETCH_QUOTE_REQUEST_FAILED'
export const GET_QUOTE_REQUEST_PROCESSING = 'GET_QUOTE_REQUEST_PROCESSING'
export const GET_QUOTE_REQUEST_SUCCEED = 'GET_QUOTE_REQUEST_SUCCEED'
export const GET_QUOTE_REQUEST_FAILED = 'GET_QUOTE_REQUEST_FAILED'
export const SAVE_QUOTE_REQUEST_PROCESSING = 'SAVE_QUOTE_REQUEST_PROCESSING'
export const SAVE_QUOTE_REQUEST_SUCCEED = 'SAVE_QUOTE_REQUEST_SUCCEED'
export const SAVE_QUOTE_REQUEST_FAILED = 'SAVE_QUOTE_REQUEST_FAILED'
export const DELETE_QUOTE_REQUEST_PROCESSING = 'DELETE_QUOTE_REQUEST_PROCESSING'
export const DELETE_QUOTE_REQUEST_SUCCEED = 'DELETE_QUOTE_REQUEST_SUCCEED'
export const DELETE_QUOTE_REQUEST_FAILED = 'DELETE_QUOTE_REQUEST_FAILED'
export const CLEAR_FORM = 'CLEAR_FORM'
