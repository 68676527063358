import { bin as binAPI } from '../api'
import { CLEAR_FORM } from '../constants/bin.constants'
export const bin = {
  searchList(query) {
    return dispatch => {
      return dispatch(binAPI.searchList(query))
    }
  },
  list(pagination, rowsPerPage, query, filterBy) {
    return dispatch => {
      return dispatch(binAPI.list(pagination, rowsPerPage, query, filterBy))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(binAPI.get(id))
    }
  },
  getObservations(id, from_date, to_date) {
    return dispatch => {
      return dispatch(binAPI.getObservations(id, from_date, to_date))
    }
  },
  count(query) {
    return dispatch => {
      return dispatch(binAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(binAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(binAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_FORM,
      })
    }
  },
}
