import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  withStyles,
  Typography,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import CustomTextField from '../CustomTextField'
import BasicSelectField from '../BasicSelectField'

import { business } from '../../actions'

import { overlay, loadingSpinner } from '../../variables/styles'
import CustomSnackbar from '../CustomSnackbar'
import CustomSwitch from '../CustomSwitch'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'
import Notes from '../Notes'
import { collections, selects } from '../../constants'

export const fields = ['id', 'name', 'address', 'type']
const baseUrl = '/business'

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    // save the business
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving business' })
  }
}

class BusinessEdit extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    dispatch: PropTypes.func,
    match: PropTypes.object,
    dirty: PropTypes.bool,
    history: PropTypes.object.isRequired,
  }

  state = {
    dialogExitWithoutSavingOpen: false,
    popupInfo: null,
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.props.get(this.props.match.params.id)
      await this.props.getBinLocationsForBusiness(this.props.match.params.id)
    } else {
      await this.props.clearForm()
    }
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }
  /* _renderPopup = () => {
    const { popupInfo } = this.state

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.loc.longitude}
          latitude={popupInfo.loc.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <InfoPopup info={popupInfo} />
        </Popup>
      )
    )
  } */
  backToList = () => {
    this.props.history.push(baseUrl)
  }
  render() {
    const {
      record,
      binLocationList,
      fetchingBinLocations,
      fetchedBinLocations,
      handleSubmit,
      submitFailed,
      pristine,
      invalid,
      saving,
      saved,
      error,
      classes,
      fetching,
      match,
      history,
    } = this.props

    return (
      <div>
        <Fade in={fetching || saving}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching || saving ? 1000 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              {record ? (
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title={`Edit ${record.name}`}
                    action={
                      <IconButton onClick={this.handleBackButton}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={24} direction="column">
                      <Grid item>
                        <Field
                          name="name"
                          component={CustomTextField}
                          label="Name"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="has_account"
                          component={CustomSwitch}
                          label="Has account"
                        />
                      </Grid>
                      {match.params.id && (
                        <Grid item>
                          <Typography variant="subtitle1">
                            Bin locations
                          </Typography>
                          {fetchingBinLocations && '...'}
                          {fetchedBinLocations &&
                          binLocationList &&
                          binLocationList.length > 0
                            ? binLocationList.map(binLocation => (
                                <Button
                                  key={binLocation.value}
                                  onClick={() =>
                                    history.push(
                                      `/bin-location/edit/${binLocation.value}`
                                    )
                                  }
                                >
                                  {binLocation.name}
                                </Button>
                              ))
                            : fetchedBinLocations
                            ? 'No bin locations found'
                            : ''}
                        </Grid>
                      )}
                      <Grid item>
                        <Typography variant="subtitle1">Address</Typography>
                        <Field
                          name="address.line_1"
                          component={CustomTextField}
                          label="Line 1"
                        />
                        <Field
                          name="address.line_2"
                          component={CustomTextField}
                          label="Line 2"
                        />
                        <Field
                          name="address.line_3"
                          component={CustomTextField}
                          label="Line 3"
                        />
                        <Field
                          name="address.post_town"
                          component={CustomTextField}
                          label="Post town"
                        />
                        <Field
                          name="address.postcode"
                          component={CustomTextField}
                          label="Postcode"
                        />
                        <Field
                          name="business_types[0]"
                          component={BasicSelectField}
                          label="Type"
                          data={selects.business_types}
                          valueField="value"
                          textField="text"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={pristine || fetching || invalid || saving}
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                  </CardActions>
                </form>
              ) : (
                <CardContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <h1>No such business found</h1>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            {record.id ? (
              <Notes id={record.id} collection={collections.businesses} />
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        <CustomSnackbar
          variant={submitFailed ? 'error' : 'success'}
          open={saved || Boolean(error)}
          message={error || 'Saved'}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { business } = state
  return {
    ...business,
    fields,
    initialValues: business.record,
  }
}

const mapDispatchToProps = { ...business }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'business',
      onSubmit,
      validate,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(BusinessEdit)
  )
)
