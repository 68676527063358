import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'

import { binbag_request as binbagRequestActions } from '../../actions'
import CustomTable from '../Table/CustomTable'
import { selects } from '../../constants'

const baseUrl = '/binbag-request'

class QuoteRequestList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    orderBy: ['created_at', 'desc'],
    filterBy: {},
    searchText: '',
    paidStatus: '',
    fields: [
      { id: 'business', label: 'Business' },
      { id: 'amount', label: 'Amount' },
      {
        id: 'on_account',
        type: 'checkbox',
        label: 'On account',
      },
      {
        id: 'paid_status',
        label: 'Paid status',
        select: {
          valueField: 'value',
          textField: 'text',
          data: selects.paid_statuses,
          handleChange: value => {
            this.setState(
              {
                ...this.state,
                filterBy: { ...this.state.filterBy, paid_status: value },
              },
              () => this._getAll()
            )
          },
        },
      },
      { id: 'created_at', label: 'Requested', enableSort: true },
      { id: 'test', type: 'checkbox', label: 'Test' },
    ],
  }

  static propTypes = {
    record: PropTypes.object,
    total: PropTypes.number,
    list: PropTypes.array.isRequired,
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    deleted: PropTypes.bool.isRequired,
    searchText: PropTypes.string,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    fetch: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search)
    this.setState(
      {
        searchText: query.searchText || '',
        page: query.page || 0,
      },
      () => {
        this._getAll()
      }
    )
  }
  _getAll = (direction = 0) => {
    let pagination = {}
    if (direction !== 0 && this.props.list[0]) {
      pagination = {
        direction,
        id:
          direction === -1
            ? Object.keys(this.props.list[0])[0]
            : Object.keys(this.props.list[this.props.list.length - 1])[0],
      }
    }
    this.props.fetch(
      pagination,
      this.state.rowsPerPage,
      this.state.orderBy,
      this.state.filterBy
    )
  }
  handleChangePage = page => {
    const direction = page - this.state.page
    this.setState({ page: page }, () => {
      this._getAll(direction)
    })
  }
  handleChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      this._getAll()
    })
  }
  handleRequestSort = (property, order) => {
    this.setState({ orderBy: [property, order] }, () => this._getAll())
  }
  handleDeleteAction = async id => {
    await this.props.remove(id)
    this._getAll()
  }
  render() {
    const { list, total, fetching, fetched, history } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push(`${baseUrl}/add`)}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Binbag requests'}
          total={total}
          history={history}
          fetching={fetching}
          fetched={fetched}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={Number(this.state.page)}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath={`${baseUrl}/edit/`}
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: `${baseUrl}/edit/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.binbag_request.total,
  list: state.binbag_request.list,
  fetching: state.binbag_request.fetching,
  fetched: state.binbag_request.fetched,
  deleted: state.binbag_request.deleted,
})

const mapDispatchToProps = { ...binbagRequestActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestList)
