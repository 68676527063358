import {
  COUNT_BIN_FAILED,
  COUNT_BIN_SUCCEED,
  COUNT_BIN_PROCESSING,
  FETCH_BIN_FAILED,
  FETCH_BIN_SUCCEED,
  FETCH_BIN_PROCESSING,
  GET_BIN_FAILED,
  GET_BIN_SUCCEED,
  GET_BIN_PROCESSING,
  SAVE_BIN_FAILED,
  SAVE_BIN_SUCCEED,
  SAVE_BIN_PROCESSING,
  DELETE_BIN_FAILED,
  DELETE_BIN_SUCCEED,
  DELETE_BIN_PROCESSING,
  GET_BIN_OBSERVATIONS_FAILED,
  GET_BIN_OBSERVATIONS_SUCCEED,
  GET_BIN_OBSERVATIONS_PROCESSING,
  CLEAR_FORM,
} from '../constants/bin.constants'
import { defaultLatLng } from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  fetchingObservations: false,
  fetchedObservations: false,
  errorMessage: null,
  list: [],
  total: 0,
  record: {
    name: '',
    type: '',
    bin_location: {},
    loc: defaultLatLng,
    lockable: false,
    size: '',
  },
  observations: [],
}

const bin = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BIN_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_BIN_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_BIN_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_BIN_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
        errorMessage: action.payload.error,
      }
    case GET_BIN_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_BIN_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case COUNT_BIN_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_BIN_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_BIN_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_BIN_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_BIN_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_BIN_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_BIN_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_BIN_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_BIN_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case GET_BIN_OBSERVATIONS_FAILED:
      return {
        ...state,
        fetchingObservations: false,
        fetchedObservations: false,
        errorMessage: action.payload.error,
      }
    case GET_BIN_OBSERVATIONS_SUCCEED:
      return {
        ...state,
        fetchingObservations: false,
        fetchedObservations: true,
        observations: action.payload,
      }
    case GET_BIN_OBSERVATIONS_PROCESSING:
      return {
        ...state,
        fetchingObservations: true,
        fetchedObservations: false,
      }
    case CLEAR_FORM:
      return {
        ...state,
        record: initialState.record,
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default bin
