import { collection_request as collection_requestAPI } from '../api/modules/collection_request.api'
import { CLEAR_FORM } from '../constants/collection_request.constants'
export const collection_request = {
  fetch(pagination, rowsPerPage, orderBy, filterBy) {
    return dispatch => {
      return dispatch(
        collection_requestAPI.list(pagination, rowsPerPage, orderBy, filterBy)
      )
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(collection_requestAPI.get(id))
    }
  },
  count(query = '') {
    return dispatch => {
      return dispatch(collection_requestAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(collection_requestAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(collection_requestAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_FORM,
      })
    }
  },
}
