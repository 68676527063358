import {
  COUNT_BINBAG_ROLL_FAILED,
  COUNT_BINBAG_ROLL_SUCCEED,
  COUNT_BINBAG_ROLL_PROCESSING,
  FETCH_BINBAG_ROLL_FAILED,
  FETCH_BINBAG_ROLL_SUCCEED,
  FETCH_BINBAG_ROLL_PROCESSING,
  GET_BINBAG_ROLL_FAILED,
  GET_BINBAG_ROLL_SUCCEED,
  GET_BINBAG_ROLL_PROCESSING,
  SAVE_BINBAG_ROLL_FAILED,
  SAVE_BINBAG_ROLL_SUCCEED,
  SAVE_BINBAG_ROLL_PROCESSING,
  DELETE_BINBAG_ROLL_FAILED,
  DELETE_BINBAG_ROLL_SUCCEED,
  DELETE_BINBAG_ROLL_PROCESSING,
  CLEAR_FORM,
} from '../constants/binbag_roll.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  list: [],
  total: 0,
  record: {
    quantity: 0,
  },
}

const binbag_roll = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BINBAG_ROLL_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case FETCH_BINBAG_ROLL_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_BINBAG_ROLL_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_BINBAG_ROLL_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
      }
    case GET_BINBAG_ROLL_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_BINBAG_ROLL_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case COUNT_BINBAG_ROLL_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case COUNT_BINBAG_ROLL_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_BINBAG_ROLL_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_BINBAG_ROLL_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
      }
    case SAVE_BINBAG_ROLL_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_BINBAG_ROLL_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_BINBAG_ROLL_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
      }
    case DELETE_BINBAG_ROLL_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_BINBAG_ROLL_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case CLEAR_FORM:
      return {
        ...state,
        record: initialState.record,
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default binbag_roll
