import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import logo from '../../assets/img/binit-logo-grey.svg'
import { auth } from '../../actions'
import RegularCard from '../Cards/RegularCard.js'
import {
  Button,
  Fade,
  CircularProgress,
  Grid,
  withStyles
} from '@material-ui/core'

import CustomSnackbar from '../CustomSnackbar'
import CustomTextField from '../CustomTextField'

import { emailRegex } from '../../constants'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Email is required'
  } else {
    if (!values.email.match(emailRegex)) {
      errors.email = `Doesn't look like a valid email`
    }
  }

  return errors
}

const warn = values => {
  const warnings = {}
  return warnings
}

class RequestPasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
      showMessage: ''
    }
  }
  submit = values => {
    this.props.dispatch(auth.requestPasswordReset(values.email))
  }
  render() {
    const {
      handleSubmit,
      submitFailed,
      submitting,
      invalid,
      requestPasswordResetError,
      errorMessage,
      isRequestingPasswordReset,
      hasRequestedPasswordReset,
      classes
    } = this.props
    return (
      <Grid container justify="center">
        <Grid xs={4} item>
          <form onSubmit={handleSubmit(this.submit)}>
            <RegularCard
              cardTitle="Request password reset"
              cardSubtitle=""
              // headerColor="primary"
              mediaUrl={logo}
              mediaTitle="Binit"
              content={
                <div>
                  <Fade in={isRequestingPasswordReset || submitting}>
                    <div
                      className={classes.overlay}
                      style={{
                        zIndex: submitting || isRequestingPasswordReset ? 10 : -1
                      }}
                    >
                      <CircularProgress className={classes.loadingSpinner} />
                    </div>
                  </Fade>
                  {!hasRequestedPasswordReset ? (
                    <div style={styles.form}>
                      <Field
                        id="email"
                        name="email"
                        component={CustomTextField}
                        label="Email"
                        fullWidth
                      />
                    </div>
                  ) : (
                    <p>Requested password Reset</p>
                  )}
                </div>
              }
              footer={
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  label="Sign in"
                  disabled={invalid || submitting}
                  fullWidth={true}
                >
                  Request
                </Button>
              }
            />
          </form>
        </Grid>
        <CustomSnackbar
          variant="error"
          open={requestPasswordResetError || submitFailed}
          message={errorMessage}
        />
      </Grid>
    )
  }
}

const styles = theme => ({
  form: {
    flexGrow: 1
  },
  formControl: {}
})

RequestPasswordReset.propTypes = {
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isRequestingPasswordReset: PropTypes.bool.isRequired,
  hasRequestedPasswordReset: PropTypes.bool.isRequired,
  requestPasswordResetError: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  classes: PropTypes.object
}

const mapStateToProps = state => ({
  isRequestingPasswordReset: state.auth.isRequestingPasswordReset,
  hasRequestedPasswordReset: state.auth.hasRequestedPasswordReset,
  requestPasswordResetError: state.auth.error,
  errorMessage: state.auth.errorMessage
})

export default withStyles(styles)(
  connect(mapStateToProps)(
    reduxForm({
      form: 'request_password_reset',
      validate,
      warn
    })(RequestPasswordReset)
  )
)
