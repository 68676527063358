import {
  FETCH_BINBAG_REQUEST_FAILED,
  FETCH_BINBAG_REQUEST_SUCCEED,
  FETCH_BINBAG_REQUEST_PROCESSING,
  GET_BINBAG_REQUEST_FAILED,
  GET_BINBAG_REQUEST_SUCCEED,
  GET_BINBAG_REQUEST_PROCESSING,
  SAVE_BINBAG_REQUEST_FAILED,
  SAVE_BINBAG_REQUEST_SUCCEED,
  SAVE_BINBAG_REQUEST_PROCESSING,
  DELETE_BINBAG_REQUEST_FAILED,
  DELETE_BINBAG_REQUEST_SUCCEED,
  DELETE_BINBAG_REQUEST_PROCESSING,
  collections,
} from '../../constants'
import { db } from '../firebase'
import { paginate } from '../../utils'
import pick from 'lodash.pick'
import moment from 'moment'
import { formatMoney } from 'accounting'

const dbCollection = 'binbag_requests'

const fetch = (
  pagination,
  rowsPerPage = 10,
  orderBy = ['created_at', 'asc'],
  filterBy = {},
  query = ''
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_BINBAG_REQUEST_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      // set up order by
      const collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filterBy
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value) paginateQuery = paginateQuery.where(fieldPath, '==', value)
        }
      }
      // do the limit
      const limitQuery = paginateQuery.limit(rowsPerPage)
      const binbagRequests = await limitQuery.get()
      const list = []
      let i = 0
      for (const binbagRequestDoc of binbagRequests.docs) {
        const binbagRequestData = binbagRequestDoc.data()
        let business = ''
        if (binbagRequestData.business) {
          const businessRef = await binbagRequestData.business.get()
          if (businessRef.exists) {
            const businessData = businessRef.data()
            business = businessData.name || ''
          }
        }
        // let user = ''
        /* if (binbagRequestData.user) {
          const userRef = await binbagRequestData.user.get()
          if (userRef.exists) {
            const userData = userRef.data()
            user = userData.displayName || ''
          }
        } */
        const amount = binbagRequestData.amount
          ? formatMoney(binbagRequestData.amount / 100, '£')
          : '-'
        list[i] = {
          [binbagRequestDoc.id]: {
            ...pick(binbagRequestData, [
              'on_account',
              'paid_status',
              'quantity',
              'receipt_email',
              'status',
              'type',
              'test',
            ]),
            amount,
            business,
            // user,
            created_at: binbagRequestData.created_at
              ? moment(binbagRequestData.created_at.toDate()).fromNow()
              : '',
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_BINBAG_REQUEST_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_BINBAG_REQUEST_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_BINBAG_REQUEST_PROCESSING,
    })
    const collectionRef = db.collection(dbCollection)
    const docRef = collectionRef.doc(id)
    const binbagRequestRef = await docRef.get()
    if (binbagRequestRef.exists) {
      const binbagRequestData = binbagRequestRef.data()
      let business = {}
      if (binbagRequestData.business) {
        const businessRef = await binbagRequestData.business.get()
        if (businessRef.exists) {
          const businessData = businessRef.data()
          business = {
            name: businessData.name,
            value: businessRef.id,
          }
        }
      }
      // let user = ''
      /* if (binbagRequestData.user) {
          const userRef = await binbagRequestData.user.get()
          if (userRef.exists) {
            const userData = userRef.data()
            user = userData.displayName || ''
          }
        } */
      const amount = binbagRequestData.amount
        ? formatMoney(binbagRequestData.amount / 100, '£')
        : '-'
      const binbagRequest = {
        ...pick(binbagRequestData, [
          'on_account',
          'paid_status',
          'quantity',
          'receipt_email',
          'status',
          'type',
          'test',
        ]),
        amount,
        business,
      }

      return dispatch({
        type: GET_BINBAG_REQUEST_SUCCEED,
        payload: {
          record: {
            ...binbagRequest,
            id: binbagRequestRef.id,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BINBAG_REQUEST_FAILED,
        payload: {
          error: `No such binbag request: ${id}`,
        },
      })
    }
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_BINBAG_REQUEST_PROCESSING,
    })

    let business = null

    if (values.business.value) {
      const businessRef = db.collection(collections.businesses)
      const businessSnap = await businessRef.doc(values.business.value).get()
      if (businessSnap.exists) {
        business = businessSnap.ref
      }
    }

    const data = {
      ...values,
      business,
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(data, { merge: true })
      } else {
        await db.collection(dbCollection).add(data)
      }
      return dispatch({
        type: SAVE_BINBAG_REQUEST_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: SAVE_BINBAG_REQUEST_FAILED,
        payload: {
          error: 'Error: Saving quote request field',
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_BINBAG_REQUEST_PROCESSING,
    })
    try {
      const promises = []
      for (const id of ids) {
        promises.push(
          db
            .collection(dbCollection)
            .doc(id)
            .delete()
        )
      }
      await Promise.all(promises)
      return dispatch({
        type: DELETE_BINBAG_REQUEST_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_BINBAG_REQUEST_FAILED,
      })
    }
  }
}

export const binbag_request = {
  fetch,
  get,
  save,
  remove,
}
