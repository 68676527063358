import {
  FETCH_COMMENT_FAILED,
  FETCH_COMMENT_SUCCEED,
  FETCH_COMMENT_PROCESSING,
  GET_COMMENT_FAILED,
  GET_COMMENT_SUCCEED,
  GET_COMMENT_PROCESSING,
  SAVE_COMMENT_FAILED,
  SAVE_COMMENT_SUCCEED,
  SAVE_COMMENT_PROCESSING,
  DELETE_COMMENT_FAILED,
  DELETE_COMMENT_SUCCEED,
  DELETE_COMMENT_PROCESSING,
  CLEAR_COMMENT_FORM,
} from '../constants/comment.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  list: [],
  total: 0,
  record: {
    message: '',
    user: null,
    object: null,
  },
}

const comment = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case FETCH_COMMENT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_COMMENT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_COMMENT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
      }
    case GET_COMMENT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_COMMENT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case SAVE_COMMENT_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
      }
    case SAVE_COMMENT_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_COMMENT_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_COMMENT_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
      }
    case DELETE_COMMENT_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_COMMENT_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case CLEAR_COMMENT_FORM:
      return {
        ...state,
        record: initialState.record,
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default comment
