import { comment as commentAPI } from '../api/modules/comment.api'
import { CLEAR_COMMENT_FORM } from '../constants/comment.constants'
export const comment = {
  listForObject(id, collection) {
    return dispatch => {
      return dispatch(commentAPI.listForObject(id, collection))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(commentAPI.get(id))
    }
  },
  save(values, id, collection) {
    return dispatch => {
      return dispatch(commentAPI.save(values, id, collection))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(commentAPI.remove([id]))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_COMMENT_FORM,
      })
    }
  },
}
