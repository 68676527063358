import {
  COUNT_BUSINESS_FAILED,
  COUNT_BUSINESS_SUCCEED,
  COUNT_BUSINESS_PROCESSING,
  FETCH_BUSINESS_FAILED,
  FETCH_BUSINESS_SUCCEED,
  FETCH_BUSINESS_PROCESSING,
  GET_BUSINESS_FAILED,
  GET_BUSINESS_SUCCEED,
  GET_BUSINESS_PROCESSING,
  SAVE_BUSINESS_FAILED,
  SAVE_BUSINESS_SUCCEED,
  SAVE_BUSINESS_PROCESSING,
  DELETE_BUSINESS_FAILED,
  DELETE_BUSINESS_SUCCEED,
  DELETE_BUSINESS_PROCESSING,
  GET_BIN_LOCATIONS_FOR_BUSINESS_PROCESSING,
  GET_BIN_LOCATIONS_FOR_BUSINESS_SUCCESS,
  GET_BIN_LOCATIONS_FOR_BUSINESS_FAILED,
  CLEAR_BUSINESS_FORM,
} from '../constants/business.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  list: [],
  total: 0,
  binLocationList: [],
  record: {
    name: '',
    address: {
      line_1: '',
      line_2: '',
      line_3: '',
      post_town: '',
      postcode: '',
    },
    business_types: [],
    contract_start_date: null,
    has_account: false,
    start_date: null,
    type: 'customer',
  },
}

const business = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        snackbarMessage: action.payload.error,
      }
    case FETCH_BUSINESS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_BUSINESS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_BUSINESS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
        snackbarMessage: action.payload.error,
      }
    case GET_BUSINESS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_BUSINESS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case COUNT_BUSINESS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: action.payload.error,
      }
    case COUNT_BUSINESS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_BUSINESS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_BUSINESS_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: action.payload.error,
      }
    case SAVE_BUSINESS_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_BUSINESS_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_BUSINESS_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: action.payload.error,
      }
    case DELETE_BUSINESS_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_BUSINESS_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case GET_BIN_LOCATIONS_FOR_BUSINESS_PROCESSING:
      return {
        ...state,
        fetchingBinLocations: true,
        fetchedBinLocations: false,
        binLocationList: [],
      }
    case GET_BIN_LOCATIONS_FOR_BUSINESS_SUCCESS:
      return {
        ...state,
        fetchingBinLocations: false,
        fetchedBinLocations: true,
        binLocationList: action.payload,
      }
    case GET_BIN_LOCATIONS_FOR_BUSINESS_FAILED:
      return {
        ...state,
        fetchingBinLocations: false,
        fetchedBinLocations: false,
        binLocationList: [],
      }
    case CLEAR_BUSINESS_FORM:
      return {
        ...state,
        record: initialState.record,
        binLocationList: [],
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default business
