import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  withStyles,
  Typography,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { Line } from 'react-chartjs-2'
import QRCode from 'qrcode.react'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import 'rc-calendar/assets/index.css'
import enGB from 'rc-calendar/lib/locale/en_GB'
import moment from 'moment'
import 'moment/locale/en-gb'

import { bin } from '../../actions'
import { bin as binApi } from '../../api/modules/bin.api'
import { bin_location as bin_locationAPI } from '../../api/modules/bin_location.api'
import BasicSelectField from '../BasicSelectField'
import CustomTextField from '../CustomTextField'

import { overlay, loadingSpinner } from '../../variables/styles'

import { selects, appBaseUrl } from '../../constants'
import DownshiftSingle from '../CustomSelect/DownshiftSingle'
import CustomSnackbar from '../CustomSnackbar'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'
import MapField from '../Map'
import CustomSwitch from '../CustomSwitch'

export const fields = ['id', 'name', 'type', 'bin_location']

const form = 'bin'
const baseUrl = '/bin'

const now = moment()
const defaultFromDate = now.clone().subtract(100, 'days')
const defaultToDate = now.clone()

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  if (!values.type) {
    errors.type = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  values.id = props.match.params.id
  try {
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving bin' })
  }
}

class BinEdit extends Component {
  state = {
    from_date: defaultFromDate,
    to_date: defaultToDate,
    dialogExitWithoutSavingOpen: false,
    graph: {
      options: {
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              return (
                data.datasets[tooltipItem[0].datasetIndex].data[
                  tooltipItem[0].index
                ].notes || ''
              )
            },
            label: (tooltipItem, data) => {
              return (
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                  .y + '%' || ''
              )
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              position: 'bottom',
              time: {
                min: defaultFromDate.clone(),
                max: defaultToDate.clone(),
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 120,
                callback: function(value, index, values) {
                  return value + '%'
                },
              },
            },
          ],
        },
      },
    },
  }
  static propTypes = {
    ...reduxFormPropTypes,
    record: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    observations: PropTypes.array,
    fields: PropTypes.array.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
    fetchingObservations: PropTypes.bool.isRequired,
    fetchedObservations: PropTypes.bool.isRequired,
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.get(this.props.match.params.id)
      await this.getBinObservations()
    } else {
      this.props.clearForm()
    }
  }

  onCalendarChange = dates => {
    this.setState(
      {
        from_date: dates[0],
        to_date: dates[1],
      },
      async () => {
        await this.getBinObservations()
      }
    )
  }

  getBinObservations = async () => {
    await this.props.getObservations(
      this.props.match.params.id,
      this.state.from_date.toISOString(),
      this.state.to_date.toISOString()
    )
    this.setState({
      graph: {
        ...this.state.graph,
        options: {
          ...this.state.graph.options,
          scales: {
            ...this.state.graph.options.scales,
            xAxes: [
              {
                ...this.state.graph.options.scales.xAxes[0],
                time: {
                  min: this.state.from_date,
                  max: this.state.to_date,
                },
              },
            ],
          },
        },
      },
    })
  }

  getBinLocationSuggestions = async value => {
    try {
      const results = await bin_locationAPI.search(value)
      return results.hits.length > 0
        ? results.hits.map(result => ({
            name: result.name,
            value: result.objectID,
          }))
        : []
    } catch (err) {
      console.error(err)
      return []
    }
  }

  handleExportObservationsForBin = async () => {
    await binApi.exportObservations(
      this.props.match.params.id,
      this.state.from_date.toISOString(),
      this.state.to_date.toISOString()
    )
  }

  handleClickBinLocationLink = value => {
    this.props.history.push(`/bin-location/edit/${value}`)
  }
  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }
  backToList = event => {
    this.props.history.push(baseUrl)
  }
  render() {
    const {
      record,
      handleSubmit,
      submitFailed,
      saved,
      error,
      fetching,
      pristine,
      invalid,
      submitting,
      classes,
      observations,
      fetchingObservations,
    } = this.props
    const datasets = []
    if (observations) {
      let dataset = {}
      if (observations.length > 0) {
        dataset = {
          label: record.name,
          data: observations.map(datum => {
            return {
              x: moment(datum.created_at).toISOString(),
              y: datum.fullness,
              notes: `${moment(datum.created_at).calendar(null, {
                sameElse: 'MM/DD/YYYY HH:mm',
              })}, by ${datum.user.displayName || datum.user.id || ''}: ${
                datum.notes
              }`,
            }
          }),
          pointBackgroundColor: observations.map(datum =>
            datum.fullness > 100 ? 'red' : 'green'
          ),
          pointBorderColor: observations.map(datum =>
            datum.fullness > 100 ? 'red' : 'green'
          ),
        }
        datasets.push(dataset)
      }
    }
    const title = this.props.match.params.id
      ? 'Edit: ' + record.name
      : 'Add new'
    const binLink = `${appBaseUrl}${baseUrl}/${record.name}`
    return (
      <div>
        {fetching ? (
          <Fade in={fetching}>
            <div
              className={classes.overlay}
              style={{ zIndex: fetching ? 10 : -1 }}
            >
              <CircularProgress className={classes.loadingSpinner} />
            </div>
          </Fade>
        ) : (
          ''
        )}
        {this.props.match.params.id ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title={title}
                    action={
                      <IconButton onClick={this.backToList}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={24} direction="column">
                      <Grid item>
                        <Grid container spacing={24}>
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={24} direction="column">
                              <Grid item>
                                <Field
                                  name="name"
                                  component={CustomTextField}
                                  label="Name"
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  name="type"
                                  component={BasicSelectField}
                                  label="Type"
                                  data={selects.waste_types}
                                  valueField="value"
                                  textField="text"
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  name="bin_location"
                                  component={DownshiftSingle}
                                  label="Bin location"
                                  placeholder="Select a bin location"
                                  getSuggestions={
                                    this.getBinLocationSuggestions
                                  }
                                  handleClickLink={
                                    this.handleClickBinLocationLink
                                  }
                                />
                              </Grid>

                              <Grid item>
                                <Field
                                  name="lockable"
                                  component={CustomSwitch}
                                  label="Lockable"
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  name="size"
                                  component={BasicSelectField}
                                  label="Size"
                                  data={selects.sizes}
                                  valueField="value"
                                  textField="text"
                                  classes={classes}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ textAlign: 'center', paddingTop: 20 }}
                          >
                            <QRCode
                              id={record.name}
                              alt={record.name}
                              value={binLink}
                              style={{
                                height: 256,
                                width: 256,
                                margin: 'auto',
                              }}
                            />
                            <p>{binLink}</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* <Grid item>
                        <Typography variant="subtitle1">Location</Typography>
                        <Field name="loc" component={MapField} />
                      </Grid> */}
                      <Grid item>
                        <Typography variant="subtitle1">
                          Observations
                        </Typography>
                        <RangeCalendar
                          defaultSelectedValue={[
                            defaultFromDate,
                            defaultToDate,
                          ]}
                          onSelect={this.onCalendarChange}
                          format="DD-MM-YYYY"
                          locale={enGB}
                          className="text-center my-5 mx-auto"
                        />
                      </Grid>
                      <Grid item style={{ position: 'relative' }}>
                        <Fade in={fetchingObservations}>
                          <div
                            className={classes.overlay}
                            style={{ zIndex: fetchingObservations ? 100 : -1 }}
                          >
                            <CircularProgress
                              className={classes.loadingSpinner}
                            />
                          </div>
                        </Fade>
                        <Line
                          data={{ labels: [record.name], datasets }}
                          width={100}
                          height={50}
                          options={this.state.graph.options}
                          className={classes.graph}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      href={`${
                        process.env.REACT_APP_API_BASE_URI
                      }/v1/observations/export/csv?bin=${
                        this.props.match.params.id
                      }&from_date=${this.state.from_date.toISOString()}&to_date=${this.state.to_date.toISOString()}`}
                    >
                      EXPORT
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={pristine || fetching || submitting || invalid}
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title={title}
                    action={
                      <IconButton onClick={this.backToList}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Field
                      name="name"
                      component={CustomTextField}
                      label="Name"
                    />
                    <Field
                      name="type"
                      component={BasicSelectField}
                      label="Type"
                      data={selects.waste_types}
                      valueField="value"
                      textField="text"
                      classes={classes}
                    />
                    <Field
                      name="bin_location"
                      margin="normal"
                      component={DownshiftSingle}
                      label="Bin location"
                      placeholder="Select a bin location"
                      getSuggestions={this.getBinLocationSuggestions}
                      handleClickLink={this.handleClickBinLocationLink}
                    />
                    <Grid item>
                      <Typography variant="subtitle1">Location</Typography>
                      <Field name="loc" component={MapField} />
                    </Grid>
                    <Field
                      name="lockable"
                      component={CustomSwitch}
                      label="Lockable"
                    />
                    <Field
                      name="size"
                      component={BasicSelectField}
                      label="Size"
                      data={selects.sizes}
                      valueField="value"
                      textField="text"
                      classes={classes}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={pristine || fetching || submitting || invalid}
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        )}

        <CustomSnackbar
          variant={submitFailed ? 'error' : 'success'}
          open={saved || Boolean(error)}
          message={error || 'Saved'}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ bin }) => {
  return {
    ...bin,
    fields,
    initialValues: bin.record,
  }
}

const mapDispatchToProps = { ...bin }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  graph: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form,
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(BinEdit)
  )
)
