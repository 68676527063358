import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  reduxForm,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import startCase from 'lodash/startCase'

import CustomTextField from '../CustomTextField'
import CustomSwitch from '../CustomSwitch'
import CustomSnackbar from '../CustomSnackbar'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'

import { user } from '../../actions'

import { overlay, loadingSpinner } from '../../variables/styles'
import BasicSelectField from '../BasicSelectField'
import { selects } from '../../constants'
import { business } from '../../api/modules/business.api'
import DownshiftSingle from '../CustomSelect/DownshiftSingle'

const form = 'user'
export const fields = ['email', 'displayName', 'notifications']
const baseUrl = '/user'

const validate = values => {
  const errors = {}
  for (const field in values) {
    if (['email', 'name', 'code'].indexOf(field) > -1) {
      const value = values[field]
      if (!value) {
        errors[field] = 'Required'
      }
    }
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving the user' })
  }
}

class CollectionRequestEdit extends Component {
  state = {
    dialogExitWithoutSavingOpen: false,
  }

  static propTypes = {
    ...reduxFormPropTypes,
    record: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.initialise(this.props.match.params.id)
    } else {
      this.props.clearForm()
    }
  }

  getBusinessSuggestions = async value => {
    try {
      const results = await business.search(value)
      return results.hits.length > 0
        ? results.hits.map(result => ({
            name: result.name,
            value: result.objectID,
          }))
        : []
    } catch (err) {
      console.error(err)
      return []
    }
  }
  handleClickBusinessLink = value => {
    this.props.history.push(`/business/edit/${value}`)
  }
  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }

  backToList = () => {
    this.props.history.push(baseUrl)
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const {
      saved,
      record,
      handleSubmit,
      pristine,
      invalid,
      submitting,
      submitFailed,
      error,
      classes,
      fetching,
      match,
    } = this.props
    const name = record ? record.displayName : ''
    const title = this.props.match.params.id ? `Edit ${name}` : 'Add new'
    return (
      <div>
        <Fade in={fetching || submitting}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        {match.params.id ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <CardHeader
                    title={title}
                    action={
                      <IconButton onClick={this.handleBackButton}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption" gutterBottom>
                          email
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {record.email}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Field
                          name="displayName"
                          component={CustomTextField}
                          margin="normal"
                          label="Name"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="role"
                          component={BasicSelectField}
                          label="Role"
                          data={selects.roles}
                          valueField="value"
                          textField="text"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="code"
                          component={CustomTextField}
                          label="Binit Code"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="business"
                          component={DownshiftSingle}
                          label="Business"
                          placeholder="Select a business"
                          getSuggestions={this.getBusinessSuggestions}
                          handleClickLink={this.handleClickBusinessLink}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">Notifications</Typography>
                        {Object.keys(record.notifications).map(category => {
                          return (
                            <Grid container key={category}>
                              {Object.keys(record.notifications[category]).map(
                                notification => {
                                  const label = startCase(notification) || ''
                                  return (
                                    <Grid item key={notification}>
                                      <Field
                                        name={`notifications.${category}.${notification}`}
                                        component={CustomSwitch}
                                        label={label}
                                      />
                                    </Grid>
                                  )
                                }
                              )}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={pristine || fetching || submitting || invalid}
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <form onSubmit={handleSubmit}>
                  <Card
                    cardTitle={'Add new user'}
                    cardAction={
                      <IconButton onClick={this.backToList}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  >
                    <CardHeader
                      title={'Add new user'}
                      action={
                        <IconButton onClick={this.handleBackButton}>
                          <ArrowBackIcon />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <Grid container direction="column">
                        <Grid item>
                          <Field
                            name="email"
                            component={CustomTextField}
                            margin="normal"
                            label="Email"
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            name="displayName"
                            component={CustomTextField}
                            margin="normal"
                            label="Name"
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            name="role"
                            component={BasicSelectField}
                            label="Role"
                            data={selects.roles}
                            valueField="value"
                            textField="text"
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            name="business"
                            component={DownshiftSingle}
                            label="Business"
                            placeholder="Select a business"
                            getSuggestions={this.getBusinessSuggestions}
                            handleClickLink={this.handleClickBusinessLink}
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            name="code"
                            component={CustomTextField}
                            label="Binit Code"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting || invalid}
                      >
                        Submit
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </Card>
            </Grid>
          </Grid>
        )}

        <CustomSnackbar
          variant={submitFailed ? 'error' : 'success'}
          open={saved || Boolean(error)}
          message={error || 'Saved'}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  record: user.record,
  fetching: user.fetching,
  fetched: user.fetched,
  saving: user.saving,
  saved: user.saved,
  fields,
  initialValues: user.record,
})

const mapDispatchToProps = {
  initialise: user.get,
  ...user,
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form,
      onSubmit,
      validate,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(CollectionRequestEdit)
  )
)
