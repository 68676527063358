import { business as businessAPI } from '../api'
import { CLEAR_BUSINESS_FORM } from '../constants/business.constants'

export const business = {
  searchList(query) {
    return dispatch => {
      return dispatch(businessAPI.searchList(query))
    }
  },
  list(pagination, rowsPerPage, query, filterBy) {
    return dispatch => {
      return dispatch(
        businessAPI.list(pagination, rowsPerPage, query, filterBy)
      )
    }
  },
  getBinLocationsForBusiness(id) {
    return dispatch => {
      return dispatch(businessAPI.getBinLocationsForBusiness(id))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(businessAPI.get(id))
    }
  },
  count(query = '') {
    return dispatch => {
      return dispatch(businessAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(businessAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(businessAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_BUSINESS_FORM,
      })
    }
  },
}
