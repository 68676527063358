export const COUNT_BUSINESS_FAILED = 'COUNT_BUSINESS_FAILED'
export const COUNT_BUSINESS_SUCCEED = 'COUNT_BUSINESS_SUCCEED'
export const COUNT_BUSINESS_PROCESSING = 'COUNT_BUSINESS_PROCESSING'
export const FETCH_BUSINESS_FAILED = 'FETCH_BUSINESS_FAILED'
export const FETCH_BUSINESS_SUCCEED = 'FETCH_BUSINESS_SUCCEED'
export const FETCH_BUSINESS_PROCESSING = 'FETCH_BUSINESS_PROCESSING'
export const GET_BUSINESS_FAILED = 'GET_BUSINESS_FAILED'
export const GET_BUSINESS_SUCCEED = 'GET_BUSINESS_SUCCEED'
export const GET_BUSINESS_PROCESSING = 'GET_BUSINESS_PROCESSING'
export const SAVE_BUSINESS_FAILED = 'SAVE_BUSINESS_FAILED'
export const SAVE_BUSINESS_SUCCEED = 'SAVE_BUSINESS_SUCCEED'
export const SAVE_BUSINESS_PROCESSING = 'SAVE_BUSINESS_PROCESSING'
export const DELETE_BUSINESS_FAILED = 'DELETE_BUSINESS_FAILED'
export const DELETE_BUSINESS_SUCCEED = 'DELETE_BUSINESS_SUCCEED'
export const DELETE_BUSINESS_PROCESSING = 'DELETE_BUSINESS_PROCESSING'
export const CLEAR_BUSINESS_FORM = 'CLEAR_BUSINESS_FORM'
export const GET_BIN_LOCATIONS_FOR_BUSINESS_PROCESSING =
  'GET_BIN_LOCATIONS_FOR_BUSINESS_PROCESSING'
export const GET_BIN_LOCATIONS_FOR_BUSINESS_SUCCESS =
  'GET_BIN_LOCATIONS_FOR_BUSINESS_SUCCESS'
export const GET_BIN_LOCATIONS_FOR_BUSINESS_FAILED =
  'GET_BIN_LOCATIONS_FOR_BUSINESS_FAILED'
