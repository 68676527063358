import { user as userAPI } from '../api'
import { CLEAR_FORM, TOGGLE_SIDEBAR } from '../constants/user.constants'
export const user = {
  fetch(pagination, rowsPerPage, orderBy, filterBy) {
    return dispatch => {
      return dispatch(userAPI.list(pagination, rowsPerPage, orderBy, filterBy))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(userAPI.get(id))
    }
  },
  count(query = '') {
    return dispatch => {
      return dispatch(userAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(userAPI.save(values))
    }
  },
  remove(id) {
    return dispatch => {
      return dispatch(userAPI.remove(id))
    }
  },
  clearForm() {
    return dispatch => {
      return dispatch({
        type: CLEAR_FORM,
      })
    }
  },
  toggleSidebar() {
    return dispatch => {
      return dispatch({
        type: TOGGLE_SIDEBAR,
      })
    }
  },
}
