import {
  FETCH_BIN_FAILED,
  FETCH_BIN_SUCCEED,
  FETCH_BIN_PROCESSING,
  GET_BIN_FAILED,
  GET_BIN_SUCCEED,
  GET_BIN_PROCESSING,
  SAVE_BIN_FAILED,
  SAVE_BIN_SUCCEED,
  SAVE_BIN_PROCESSING,
  DELETE_BIN_FAILED,
  DELETE_BIN_SUCCEED,
  DELETE_BIN_PROCESSING,
  GET_BIN_OBSERVATIONS_FAILED,
  GET_BIN_OBSERVATIONS_SUCCEED,
  GET_BIN_OBSERVATIONS_PROCESSING,
} from '../../constants'
import { db, firebase, functions } from '../firebase'
import { instance } from '../axios'
import algolia from 'algoliasearch'
import debounce from 'p-debounce'
import { paginate } from '../../utils'
import { shortenAddress } from '../../utils'
const algoliaClient = algolia(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
)

const dbCollection = 'bins'
// index name should be the same as dbCollection
const searchIndex = algoliaClient.initIndex(dbCollection)

const search = debounce((query, rowsPerPage = 10) => {
  // do the limit
  return searchIndex.search({
    query,
    hitsPerPage: rowsPerPage,
  })
}, 500)

const searchList = (query, rowsPerPage = 10) => {
  return async dispatch => {
    // do the limit
    const results = await searchIndex.search({
      query,
      hitsPerPage: rowsPerPage,
    })
    let i = 0
    const list = []
    for (let hit of results.hits) {
      list[i] = {
        [hit.objectID]: {
          name: hit.name,
          type: hit.type,
          size: hit.size,
          lockable: hit.lockable,
          bin_location: '',
        },
      }
      i++
    }
    return dispatch({
      type: FETCH_BIN_SUCCEED,
      payload: { list },
    })
  }
}

const list = (
  pagination,
  rowsPerPage = 10,
  orderBy = ['name', 'asc'],
  filterBy = {},
  query = ''
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_BIN_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      // set up order by
      const collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filter by
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value) paginateQuery = paginateQuery.where(fieldPath, '==', value)
        }
      }
      // do the limit
      const limitQuery = paginateQuery.limit(rowsPerPage)
      const bins = await limitQuery.get()
      const list = []
      let i = 0
      const binsData = []
      bins.forEach(bin => {
        binsData.push({
          ...bin.data(),
          id: bin.id,
        })
      })
      for (let binData of binsData) {
        let address = ''
        const binLocationRef = await binData.bin_location.get()
        if (binLocationRef.exists) {
          const binLocationData = binLocationRef.data()
          address = shortenAddress(binLocationData.address)
        }
        list[i] = {
          [binData.id]: {
            name: binData.name,
            type: binData.type,
            size: binData.size,
            lockable: binData.lockable,
            bin_location: address,
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_BIN_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_BIN_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_BIN_PROCESSING,
    })
    const binsRef = db.collection(dbCollection)
    const binRef = binsRef.doc(id)
    const binDoc = await binRef.get()
    if (binDoc.exists) {
      const binData = binDoc.data()
      let bin_location = {}
      if (binData.bin_location) {
        const binLocationRef = await binData.bin_location.get()
        if (binLocationRef.exists) {
          const binLocationData = binLocationRef.data()
          bin_location = {
            name: binLocationData.name || '',
            value: binLocationRef.id,
          }
        }
      }
      return dispatch({
        type: GET_BIN_SUCCEED,
        payload: {
          record: {
            id: binDoc.id,
            name: binData.name,
            type: binData.type,
            bin_location,
            size: binData.size,
            lockable: binData.lockable,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BIN_FAILED,
        payload: {
          error: `No such bin: ${id}`,
        },
      })
    }
  }
}

const getObservations = (id, from_date, to_date) => {
  return async dispatch => {
    dispatch({
      type: GET_BIN_OBSERVATIONS_PROCESSING,
    })
    try {
      const getObservationsForBin = functions.httpsCallable(
        'getObservationsForBin'
      )
      const {
        data: { observations },
      } = await getObservationsForBin({
        bin: id,
        from_date,
        to_date,
      })
      return dispatch({
        type: GET_BIN_OBSERVATIONS_SUCCEED,
        payload: observations,
      })
    } catch (err) {
      return dispatch({
        type: GET_BIN_OBSERVATIONS_FAILED,
        payload: {
          error: `Could not get observations`,
        },
      })
    }
  }
}

const exportObservations = (id, from_date, to_date) => {
  return instance.get('/v1/observations/export/csv', {
    bin: id,
    from_date,
    to_date,
  })
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_BIN_PROCESSING,
    })
    // const bin_locations = []
    let bin_location = null
    if (values.bin_location) {
      const binLocationRef = db
        .collection('bin_locations')
        .doc(values.bin_location.value)
      const binLocationSnap = await binLocationRef.get()
      if (binLocationSnap.exists) {
        bin_location = binLocationSnap.ref
      }
    }
    const binData = {
      name: values.name,
      type: values.type,
      bin_location,
      lockable: values.lockable,
      size: values.size,
      loc: values.loc
        ? new firebase.firestore.GeoPoint(
          values.loc.latitude,
          values.loc.longitude
        )
        : null,
    }
    if (values.id !== undefined && values.id !== null && values.id !== '') {
      try {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(binData, { merge: true })
        return dispatch({
          type: SAVE_BIN_SUCCEED,
        })
      } catch (err) {
        console.error(err)
        return dispatch({
          type: SAVE_BIN_FAILED,
          payload: {
            error: 'Error: Saving bin',
          },
        })
      }
    } else {
      try {
        await db.collection(dbCollection).add(binData)
        return dispatch({
          type: SAVE_BIN_SUCCEED,
        })
      } catch (err) {
        console.error(err)
        return dispatch({
          type: SAVE_BIN_FAILED,
          payload: {
            error: 'Error: Saving new bin',
          },
        })
      }
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_BIN_PROCESSING,
    })
    try {
      for (const id of ids) {
        await db
          .collection(dbCollection)
          .doc(id)
          .delete()
      }
      return dispatch({
        type: DELETE_BIN_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_BIN_FAILED,
      })
    }
  }
}

export const bin = {
  search,
  searchList,
  list,
  get,
  getObservations,
  exportObservations,
  save,
  remove,
}
