export const FETCH_COLLECTION_REQUEST_PROCESSING =
  'FETCH_COLLECTION_REQUEST_PROCESSING'
export const FETCH_COLLECTION_REQUEST_SUCCEED =
  'FETCH_COLLECTION_REQUEST_SUCCEED'
export const FETCH_COLLECTION_REQUEST_FAILED = 'FETCH_COLLECTION_REQUEST_FAILED'
export const GET_COLLECTION_REQUEST_PROCESSING =
  'GET_COLLECTION_REQUEST_PROCESSING'
export const GET_COLLECTION_REQUEST_SUCCEED = 'GET_COLLECTION_REQUEST_SUCCEED'
export const GET_COLLECTION_REQUEST_FAILED = 'GET_COLLECTION_REQUEST_FAILED'
export const SAVE_COLLECTION_REQUEST_PROCESSING =
  'SAVE_COLLECTION_REQUEST_PROCESSING'
export const SAVE_COLLECTION_REQUEST_SUCCEED = 'SAVE_COLLECTION_REQUEST_SUCCEED'
export const SAVE_COLLECTION_REQUEST_FAILED = 'SAVE_COLLECTION_REQUEST_FAILED'
export const DELETE_COLLECTION_REQUEST_PROCESSING =
  'DELETE_COLLECTION_REQUEST_PROCESSING'
export const DELETE_COLLECTION_REQUEST_SUCCEED =
  'DELETE_COLLECTION_REQUEST_SUCCEED'
export const DELETE_COLLECTION_REQUEST_FAILED =
  'DELETE_COLLECTION_REQUEST_FAILED'
export const CLEAR_FORM = 'CLEAR_FORM'
