import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth.reducers'
import bin from './bin.reducers'
import user from './user.reducers'
import bin_location from './bin_location.reducers'
import business from './business.reducers'
import collection_request from './collection_request.reducers'
import quote_request from './quote_request.reducers'
import binbag_request from './binbag_request.reducers'
import binbag_roll from './binbag_roll.reducers'
import comment from './comment.reducers'

const rootReducer = combineReducers({
  auth,
  bin,
  bin_location,
  business,
  collection_request,
  quote_request,
  binbag_request,
  binbag_roll,
  comment,
  user,
  form: formReducer,
})

export default rootReducer
