export * from './actionTypes.constants'
export * from './auth.constants'
export * from './bin.constants'
export * from './bin_location.constants'
export * from './business.constants'
export * from './collection_request.constants'
export * from './quote_request.constants'
export * from './binbag_request.constants'
export * from './binbag_roll.constants'
export * from './user.constants'
export * from './business.constants'

export const collections = {
  bin_collections: 'bin_collections',
  bin_locations: 'bin_locations',
  binbag_requests: 'binbag_requests',
  binbag_rolls: 'binbag_rolls',
  bins: 'bins',
  businesses: 'businesses',
  collection_requests: 'collection_requests',
  comments: 'comments',
  observations: 'observations',
  one_off_collection_requests: 'one_off_collection_requests',
  problems: 'problems',
  quote_requests: 'quote_requests',
  roles: 'roles',
  users: 'users',
}

export const strings = {}

export const selects = {
  paid_statuses: [
    { text: 'Paid', value: 'succeeded' },
    { text: 'Pending', value: 'pending' },
    { text: 'Unpaid', value: 'unpaid' },
    { text: 'Failed', value: 'failed' },
  ],
  statuses: [
    { text: 'Requested', value: 'requested' },
    { text: 'Pending', value: 'pending' },
    { text: 'Processed', value: 'processed' },
  ],
  waste_types: [
    { text: 'Rubbish', value: 'rubbish' },
    { text: 'Recycling', value: 'recycling' },
    { text: 'Food', value: 'food' },
    { text: 'Glass', value: 'glass' },
    { text: 'WEEE', value: 'WEEE' },
    { text: 'Cardboard', value: 'cardboard' },
    { text: 'Cans', value: 'cans' },
    { text: 'Mixed plastic', value: 'mixed_plastic' },
    { text: 'Confidential', value: 'confidential' },
    { text: 'Clinical', value: 'clinical' },
    { text: 'Hazardous', value: 'hazardous' },
    { text: 'Battery', value: 'battery' },
    { text: 'Toner', value: 'toner' },
  ],
  business_types: [
    { text: 'Office', value: 'office' },
    { text: 'Retail', value: 'retail' },
    { text: 'Food', value: 'food' },
    { text: 'Light Industrial', value: 'light_industrial' },
    { text: 'Other', value: 'other' },
  ],
  roles: [
    { text: 'Admin', value: 'admin' },
    { text: 'Staff', value: 'staff' },
    { text: 'Customer', value: 'customer' },
    { text: 'Collector', value: 'collector' },
    { text: 'Dev', value: 'dev' },
  ],
  sizes: [
    { text: '120', value: 120 },
    { text: '140', value: 140 },
    { text: '240', value: 240 },
    { text: '360', value: 360 },
    { text: '770', value: 770 },
    { text: '820', value: 820 },
    { text: '1100', value: 1100 },
  ],
  binbag_types: [
    { text: 'Rubbish', value: 'rubbish' },
    { text: 'Recycling', value: 'recycling' },
  ]
}

export const defaultLatLng = {
  latitude: 50.7184,
  longitude: -3.5339,
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const appBaseUrl = process.env.REACT_APP_APP_BASE_URI

const allowedRoutes = ['login', 'signup', 'request-password-reset']
export const allowedRoutesRegexp = new RegExp(allowedRoutes.join('|'))
