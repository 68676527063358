import {
  FETCH_COMMENT_FAILED,
  FETCH_COMMENT_SUCCEED,
  FETCH_COMMENT_PROCESSING,
  GET_COMMENT_FAILED,
  GET_COMMENT_SUCCEED,
  GET_COMMENT_PROCESSING,
  SAVE_COMMENT_FAILED,
  SAVE_COMMENT_SUCCEED,
  SAVE_COMMENT_PROCESSING,
  DELETE_COMMENT_FAILED,
  DELETE_COMMENT_SUCCEED,
  DELETE_COMMENT_PROCESSING,
} from '../../constants/comment.constants'
import { db, firebase } from '../firebase'

const dbCollection = 'comments'

const listForObject = (id, collection) => {
  return async dispatch => {
    dispatch({
      type: FETCH_COMMENT_PROCESSING,
    })
    const collectionRef = db.collection(dbCollection)
    const objectCollectionRef = db.collection(collection)
    const objectQuery = objectCollectionRef.doc(id)
    const objectSnap = await objectQuery.get()
    if (objectSnap.exists) {
      const results = await collectionRef
        .orderBy('created_at', 'desc')
        .where('object', '==', objectSnap.ref)
        .get()
      let i = 0
      let list = []
      for (const commentSnap of results.docs) {
        const commentData = commentSnap.data()
        const userSnap = await commentData.user.get()
        const userData = userSnap.data()
        list[i] = {
          [commentSnap.id]: {
            message: commentData.message,
            user: commentData.user.id,
            userName: userData.displayName,
            object: commentData.object.id,
            collection,
            created_at: commentData.created_at.toDate(),
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_COMMENT_SUCCEED,
        payload: { list },
      })
    } else {
      return dispatch({
        type: FETCH_COMMENT_FAILED,
        payload: {
          error: 'Error: Could not find that object',
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_COMMENT_PROCESSING,
    })
    const binLocationsRef = db.collection(dbCollection)
    const commentRef = await binLocationsRef.doc(id).get()
    if (commentRef.exists) {
      return dispatch({
        type: GET_COMMENT_SUCCEED,
        payload: {
          record: {},
        },
      })
    } else {
      return dispatch({
        type: GET_COMMENT_FAILED,
        payload: {
          error: `No such comment: ${id}`,
        },
      })
    }
  }
}

const save = (values, id, collection) => {
  return async dispatch => {
    dispatch({
      type: SAVE_COMMENT_PROCESSING,
    })

    try {
      // try to get the related object
      const objectCollectionRef = db.collection(collection)
      const objectSnap = await objectCollectionRef.doc(id).get()
      const userCollectionRef = db.collection('users')
      // get the current user
      const userUID = await firebase.auth().currentUser.uid
      const userSnap = await userCollectionRef.where('uid', '==', userUID).get()
      let userRef = null
      if (userSnap.docs.length > 0) {
        userRef = userSnap.docs[0].ref
      }
      if (objectSnap.exists) {
        const commentData = {
          message: values.message,
          object: objectSnap.ref,
          user: userRef,
        }
        if (values.id !== undefined && values.id !== null && values.id !== '') {
          await db
            .collection(dbCollection)
            .doc(values.id)
            .set(commentData, { merge: true })
        } else {
          await db.collection(dbCollection).add(commentData)
        }
        return dispatch({
          type: SAVE_COMMENT_SUCCEED,
        })
      }
      return dispatch({
        type: SAVE_COMMENT_FAILED,
        payload: {
          error: `Error: Could not find that object`,
        },
      })
    } catch (err) {
      return dispatch({
        type: SAVE_COMMENT_FAILED,
        payload: {
          error: `Error: ${err.message}`,
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_COMMENT_PROCESSING,
    })
    try {
      for (const id of ids) {
        await db
          .collection(dbCollection)
          .doc(id)
          .delete()
      }
      return dispatch({
        type: DELETE_COMMENT_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_COMMENT_FAILED,
      })
    }
  }
}

export const comment = {
  listForObject,
  get,
  save,
  remove,
}
