import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Field,
  reduxForm,
  propTypes as reduxFormPropTypes,
  SubmissionError,
} from 'redux-form'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  withStyles,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { overlay, loadingSpinner } from '../variables/styles'

import { comment } from '../actions/comments.actions'
import CustomTextField from './CustomTextField'

const form = 'notes'

const validate = values => {
  const errors = {}
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values, props.id, props.collection)
    await props.clearForm()
    await props.listForObject(props.id, props.collection)
  } catch (err) {
    console.error(err)
    throw new SubmissionError({ _error: 'Problem saving note' })
  }
}

class Notes extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    id: PropTypes.string.isRequired,
    collection: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
  }

  async componentDidMount() {
    await this.props.listForObject(this.props.id, this.props.collection)
  }

  removeComment = async id => {
    await this.props.remove(id)
    await this.props.listForObject(this.props.id, this.props.collection)
  }

  render() {
    const {
      classes,
      list,
      pristine,
      invalid,
      saving,
      handleSubmit,
      deleting,
    } = this.props
    return (
      <>
        <h2>Comments</h2>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            {list.map(comment => {
              const id = Object.keys(comment)[0]
              const values = Object.values(comment)[0]
              return (
                <>
                  <Fade in={deleting}>
                    <div
                      className={classes.overlay}
                      style={{ zIndex: deleting ? 10 : -1 }}
                    >
                      <CircularProgress className={classes.loadingSpinner} />
                    </div>
                  </Fade>
                  <Card key={id} className={classes.bubble}>
                    <CardHeader
                      action={
                        <IconButton onClick={() => this.removeComment(id)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                      title={values.message}
                      titleTypographyProps={{ variant: 'body1' }}
                      subheader={`by: ${values.userName} ${moment(
                        values.created_at
                      ).fromNow()}`}
                    />
                  </Card>
                </>
              )
            })}
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader title={`Add comment`} />
                <CardContent>
                  <Grid container spacing={24} direction="column">
                    <Grid item>
                      <Field
                        name="message"
                        component={CustomTextField}
                        label="Message"
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={pristine || invalid || saving}
                    className={classes.button}
                  >
                    SAVE
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = ({ comment }) => ({ ...comment })

const mapDispatchToProps = { ...comment }

const styles = theme => ({
  bubble: {
    /* paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2, */
    marginBottom: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    backgroundColor: '#ffffcc',
  },
  card: {
    marginTop: '20px',
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form,
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(Notes)
  )
)
