import {
  FETCH_COLLECTION_REQUEST_FAILED,
  FETCH_COLLECTION_REQUEST_SUCCEED,
  FETCH_COLLECTION_REQUEST_PROCESSING,
  GET_COLLECTION_REQUEST_FAILED,
  GET_COLLECTION_REQUEST_SUCCEED,
  GET_COLLECTION_REQUEST_PROCESSING,
  SAVE_COLLECTION_REQUEST_FAILED,
  SAVE_COLLECTION_REQUEST_SUCCEED,
  SAVE_COLLECTION_REQUEST_PROCESSING,
  DELETE_COLLECTION_REQUEST_FAILED,
  DELETE_COLLECTION_REQUEST_SUCCEED,
  DELETE_COLLECTION_REQUEST_PROCESSING,
} from '../../constants/collection_request.constants'
import { db } from '../firebase'
import { paginate } from '../../utils'
import { collections } from '../../constants'
import firebase from '@firebase/app'
import { formatMoney } from 'accounting'

const dbCollection = 'collection_requests'

const list = (
  pagination,
  rowsPerPage = 10,
  orderBy = ['created_at', 'asc'],
  filterBy = {}
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_COLLECTION_REQUEST_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      // set up order by
      let collectionQuery = collectionRef
      if (Object.keys(filterBy).length === 0)
        collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filter by
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value) paginateQuery = paginateQuery.where(fieldPath, '==', value)
        }
      }
      // do the limit
      const limitQuery = paginateQuery.limit(rowsPerPage)
      const collectionRequests = await limitQuery.get()
      const list = []
      let i = 0
      for (const collectionRequest of collectionRequests.docs) {
        const collectionRequestData = collectionRequest.data()
        let bin = ''
        if (collectionRequestData.bin) {
          const binSnap = await collectionRequestData.bin.get()
          if (binSnap.exists) {
            const binData = binSnap.data()
            bin = binData.name
          }
        }
        let business = ''
        if (collectionRequestData.business) {
          const businessSnap = await collectionRequestData.business.get()
          if (businessSnap.exists) {
            const businessData = businessSnap.data()
            if (businessData.name) business = businessData.name
          }
        }
        const amount = collectionRequestData.amount
          ? formatMoney(collectionRequestData.amount / 100, '£')
          : '-'
        list[i] = {
          [collectionRequest.id]: {
            id: collectionRequest.id,
            paid_status: collectionRequestData.paid_status,
            bin,
            business,
            amount,
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_COLLECTION_REQUEST_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_COLLECTION_REQUEST_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_COLLECTION_REQUEST_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      const docRef = collectionRef.doc(id)
      const collectionRequestRef = await docRef.get()
      if (collectionRequestRef.exists) {
        const collectionRequestData = collectionRequestRef.data()
        let bin = { value: '', name: '' }
        if (collectionRequestData.bin) {
          const binRef = await collectionRequestData.bin.get()
          if (binRef.exists) {
            const binData = binRef.data()
            bin = { value: binRef.id, name: binData.name }
          }
        }
        let business = { value: '', name: '' }
        if (collectionRequestData.business) {
          const businessRef = await collectionRequestData.business.get()
          if (businessRef.exists) {
            const businessData = businessRef.data()
            business = { value: businessRef.id, name: businessData.name }
          }
        }

        return dispatch({
          type: GET_COLLECTION_REQUEST_SUCCEED,
          payload: {
            record: {
              on_account: collectionRequestData.on_account || false,
              paid_status: collectionRequestData.paid_status,
              status: collectionRequestData.status,
              charge: collectionRequestData.charge || {},
              bin,
              business,
              created_at: collectionRequestData.created_at
                ? collectionRequestData.created_at.toDate()
                : null,
              requested_pickup_date: collectionRequestData.requested_pickup_date
                ? collectionRequestData.requested_pickup_date.toDate()
                : null,
              scheduled_pickup_date: collectionRequestData.scheduled_pickup_date
                ? collectionRequestData.scheduled_pickup_date.toDate()
                : null,
              id: collectionRequestRef.id,
            },
          },
        })
      }
    } catch (err) {
      return dispatch({
        type: GET_COLLECTION_REQUEST_FAILED,
        payload: {
          error: `No such collection request: ${id}`,
          err,
        },
      })
    }
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_COLLECTION_REQUEST_PROCESSING,
    })
    // recreate references for each bin
    const data = {
      ...values,
      created_at: firebase.firestore.Timestamp.fromDate(values.created_at),
      requested_pickup_date: firebase.firestore.Timestamp.fromDate(
        values.requested_pickup_date
      ),
      scheduled_pickup_date: firebase.firestore.Timestamp.fromDate(
        values.scheduled_pickup_date
      ),
    }
    if (values.bin && values.bin.value) {
      data.bin = db.collection(collections.bins).doc(values.bin.value)
    }
    if (values.business && values.business.value) {
      data.business = db
        .collection(collections.businesses)
        .doc(values.business.value)
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(data, { merge: true })
      } else {
        await db.collection(dbCollection).add(data)
      }
      return dispatch({
        type: SAVE_COLLECTION_REQUEST_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: SAVE_COLLECTION_REQUEST_FAILED,
        payload: {
          error: `Error: ${err.message}`,
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_COLLECTION_REQUEST_PROCESSING,
    })
    try {
      const promises = []
      for (const id of ids) {
        promises.push(
          db
            .collection(dbCollection)
            .doc(id)
            .delete()
        )
      }
      await Promise.all(promises)
      return dispatch({
        type: DELETE_COLLECTION_REQUEST_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_COLLECTION_REQUEST_FAILED,
      })
    }
  }
}

export const collection_request = {
  list,
  get,
  save,
  remove,
}
