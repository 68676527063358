// ##############################
// // // Map styles
// #############################

const mapStyle = {
  map: {
    margin: '10px 0'
  }
}

export default mapStyle
