import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  reduxForm,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  withStyles,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import CustomTextField from '../CustomTextField'
import CustomSnackbar from '../CustomSnackbar'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'

import { binbag_roll } from '../../actions'
import RegularCard from '../Cards/RegularCard.js'

import { overlay, loadingSpinner } from '../../variables/styles'
import { defaultLatLng } from '../../constants'

export const fields = [
  'id',
  'bins',
  'on_account',
  'paid_status',
  'requested_pickup_date',
  'scheduled_pickup_date',
]
const form = 'binbag-roll'
const baseUrl = '/binbag-roll'

const validate = values => {
  const errors = {}
  if (!values.business_size) {
    errors.business_size = 'Required'
  }
  if (!values.business_type) {
    errors.business_type = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.postcode) {
    errors.postcode = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving binbag roll' })
  }
}

class BinbagRollEdit extends Component {
  state = {
    snackbarOpen: false,
    snackbarVariant: 'success',
    snackbarMessage: '',
    snackbarAutoHideDuration: 6000,
    dialogExitWithoutSavingOpen: false,
    popupInfo: null,
    viewport: {
      width: '100%',
      height: 250,
      ...defaultLatLng,
      zoom: 12,
    },
  }

  static propTypes = {
    ...reduxFormPropTypes,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(binbag_roll.get(this.props.match.params.id))
    } else {
      this.props.clearForm()
    }
  }

  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }

  backToList = () => {
    this.props.history.push(baseUrl)
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      saving,
      submitting,
      classes,
      fetching,
      match,
    } = this.props
    const title = this.props.match.params.id ? 'Edit Binbag Roll' : 'Add new'
    return (
      <div>
        <Fade in={fetching || submitting}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        {match.params.id ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <form noValidate autoComplete="off" handleSubmit={handleSubmit}>
                  <CardHeader
                    title={title}
                    action={
                      <IconButton onClick={this.handleBackButton}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Field
                      name="quantity"
                      component={CustomTextField}
                      margin="normal"
                      label="Quantity"
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={fetching || invalid || saving}
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <form>
            <RegularCard
              cardTitle={'Add new business'}
              cardAction={
                <IconButton>
                  <ArrowBackIcon onClick={this.backToList} />
                </IconButton>
              }
              content={
                <div>
                  <Field
                    name="quantity"
                    component={CustomTextField}
                    margin="normal"
                    label="Quantity"
                  />
                </div>
              }
              footer={
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={fetching || pristine || saving}
                  className={classes.button}
                >
                  Submit
                </Button>
              }
            />
          </form>
        )}

        <CustomSnackbar
          variant={this.state.snackbarVariant}
          open={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          autoHideDuration={this.state.snackbarAutoHideDuration}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  fetching: state.binbag_roll.fetching,
  fetched: state.binbag_roll.fetched,
  saving: state.binbag_roll.saving,
  saved: state.binbag_roll.saved,
  fields,
  initialValues: state.binbag_roll.record,
})

const mapDispatchToProps = { ...binbag_roll }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form,
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(BinbagRollEdit)
  )
)
