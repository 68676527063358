import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import logo from '../../assets/img/binit-logo-grey.svg'
import { auth } from '../../actions'
import RegularCard from '../Cards/RegularCard.js'
import {
  Button,
  Fade,
  Grid,
  CircularProgress,
  withStyles
} from '@material-ui/core'

import CustomSnackbar from '../CustomSnackbar'
import CustomTextField from '../CustomTextField'

import { emailRegex } from '../../constants'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Email is required'
  } else {
    if (!values.email.match(emailRegex)) {
      errors.email = `Doesn't look like a valid email`
    }
  }
  if (!values.password) {
    errors.password = 'Please enter a password'
  }
  return errors
}

const warn = values => {
  const warnings = {}

  return warnings
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
      showMessage: ''
    }
  }
  submit = values => {
    this.props.dispatch(auth.login(values))
  }
  render() {
    const {
      isLoggingIn,
      handleSubmit,
      loginError,
      errorMessage,
      invalid,
      submitting,
      submitFailed,
      classes
    } = this.props
    return (
      <div>
        <Grid container justify="center">
          <Grid xs={4} item>
            <form onSubmit={handleSubmit(this.submit)}>
              <RegularCard
                cardTitle="Binit CMS"
                cardSubtitle="Log in"
                mediaUrl={logo}
                mediaTitle="Binit"
                content={
                  <div style={styles.form}>
                    <Fade in={submitting || isLoggingIn}>
                      <div
                        className={classes.overlay}
                        style={{
                          zIndex: submitting ? 10 : -1
                        }}
                      >
                        <CircularProgress className={classes.loadingSpinner} />
                      </div>
                    </Fade>
                    <Field
                      id="email"
                      name="email"
                      component={CustomTextField}
                      label="Email"
                      fullWidth
                    />
                    <Field
                      id="password"
                      name="password"
                      component={CustomTextField}
                      label="Password"
                      type="password"
                    />
                  </div>
                }
                footer={
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    label="Sign in"
                    disabled={invalid || submitting}
                    fullWidth={true}
                  >
                    Login
                  </Button>
                }
              />
            </form>
          </Grid>
        </Grid>
        <CustomSnackbar
          variant="error"
          open={loginError || submitFailed}
          message={errorMessage}
        />
      </div>
    )
  }
}

const styles = theme => ({
  form: {
    flexGrow: 1,
    padding: '0 1em 1em 1em'
  },
  input: {
    display: 'flex'
  },
  formControl: {}
})

Login.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  loginError: PropTypes.bool,
  errorMessage: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  classes: PropTypes.object
}

const mapStateToProps = state => ({
  isLoggingIn: state.auth.isLoggingIn,
  isRequestingPasswordReset: state.auth.isRequestingPasswordReset,
  hasRequestedPasswordReset: state.auth.hasRequestedPasswordReset,
  userId: state.auth.userId,
  token: state.auth.token,
  loginError: state.auth.error,
  errorMessage: state.auth.errorMessage
})

export default withStyles(styles)(
  connect(mapStateToProps)(
    reduxForm({
      form: 'login',
      validate,
      warn
    })(Login)
  )
)
