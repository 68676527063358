import {
  FETCH_COLLECTION_REQUEST_FAILED,
  FETCH_COLLECTION_REQUEST_SUCCEED,
  FETCH_COLLECTION_REQUEST_PROCESSING,
  GET_COLLECTION_REQUEST_FAILED,
  GET_COLLECTION_REQUEST_SUCCEED,
  GET_COLLECTION_REQUEST_PROCESSING,
  SAVE_COLLECTION_REQUEST_FAILED,
  SAVE_COLLECTION_REQUEST_SUCCEED,
  SAVE_COLLECTION_REQUEST_PROCESSING,
  DELETE_COLLECTION_REQUEST_FAILED,
  DELETE_COLLECTION_REQUEST_SUCCEED,
  DELETE_COLLECTION_REQUEST_PROCESSING,
  CLEAR_FORM,
} from '../constants/collection_request.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  list: [],
  total: 0,
  record: {
    bin: '',
    on_account: false,
    paid_status: 'unpaid',
    requested_pickup_date: null,
    schedule_pickup_date: null,
  },
}

const collection_request = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COLLECTION_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        snackbarMessage: action.payload.error,
      }
    case FETCH_COLLECTION_REQUEST_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_COLLECTION_REQUEST_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_COLLECTION_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
        snackbarMessage: action.payload.error,
      }
    case GET_COLLECTION_REQUEST_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_COLLECTION_REQUEST_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case SAVE_COLLECTION_REQUEST_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: action.payload.error,
      }
    case SAVE_COLLECTION_REQUEST_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_COLLECTION_REQUEST_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_COLLECTION_REQUEST_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: action.payload.error,
      }
    case DELETE_COLLECTION_REQUEST_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_COLLECTION_REQUEST_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case CLEAR_FORM:
      return {
        ...state,
        record: initialState.record,
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default collection_request
