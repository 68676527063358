import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { debounce } from 'lodash'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  MergeType as MergeTypeIcon,
} from '@material-ui/icons'
import { Button, withStyles } from '@material-ui/core'

import { bin } from '../../actions'
import CustomTable from '../Table/CustomTable'
import { selects } from '../../constants'

const baseUrl = '/bin'

class BinList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    query: '',
    filterBy: {},
    fields: [
      { id: 'name', label: 'Name', enableSort: true },
      {
        id: 'type',
        label: 'Type',
        select: {
          valueField: 'value',
          textField: 'text',
          data: selects.waste_types,
          handleChange: value => {
            this.setState(
              {
                ...this.state,
                filterBy: {
                  ...this.state.filterBy,
                  type: value,
                },
              },
              () => this._getAll()
            )
          },
        },
      },
      {
        id: 'size',
        type: 'number',
        label: 'Size',
        // select: {
        //   valueField: 'value',
        //   textField: 'text',
        //   data: selects.sizes,
        //   handleChange: value => {
        //     this.setState(
        //       {
        //         ...this.state,
        //         filterBy: {
        //           ...this.state.filterBy,
        //           size: value,
        //         },
        //       },
        //       () => this._getAll()
        //     )
        //   },
        // },
      },
      {
        id: 'lockable',
        type: 'checkbox',
        label: 'Lockable',
        // select: {
        //   valueField: 'value',
        //   textField: 'text',
        //   data: [{ text: 'Lockable', value: true }, { text: 'Not lockable', value: false }],
        //   handleChange: value => {
        //     this.setState(
        //       {
        //         ...this.state,
        //         filterBy: {
        //           ...this.state.filterBy,
        //           lockable: value,
        //         },
        //       },
        //       () => this._getAll()
        //     )
        //   },
        // },
      },
      { id: 'bin_location', label: 'Bin Location' },
    ],
  }

  static propTypes = {
    total: PropTypes.number,
    list: PropTypes.array,
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    record: PropTypes.object,
    searchText: PropTypes.string,
    errorMessage: PropTypes.string,
    deleted: PropTypes.bool,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    location: PropTypes.object.isRequired,
    mergingBinsSuccess: PropTypes.func,
    mergingBinsFailure: PropTypes.func,
    classes: PropTypes.object,
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search)
    this.setState(
      {
        query: query.searchText || '',
        page: Number(query.page) || 0,
      },
      () => {
        this._getAll()
      }
    )
  }
  _getAll = (direction = 0) => {
    let pagination = {}
    if (direction !== 0 && this.props.list[0]) {
      pagination = {
        direction,
        id:
          direction === -1
            ? Object.keys(this.props.list[0])[0]
            : Object.keys(this.props.list[this.props.list.length - 1])[0],
      }
    }
    this.props.dispatch(
      bin.list(
        pagination,
        this.state.rowsPerPage,
        this.state.orderBy,
        this.state.filterBy
      )
    )
    const search = qs.stringify({
      orderBy: this.state.orderBy,
      filterBy: this.state.filterBy,
    })
    this.props.history.push({ search })
  }
  handleChangePage = page => {
    const direction = page - this.state.page
    this.setState({ page: page }, () => {
      this._getAll(direction)
    })
  }
  handleSearch = debounce(async query => {
    await this.props.dispatch(bin.searchList(query))
  }, 500)
  handleSelectChange = event => {
    this.setState({
      filterBinGenus: event.target.value,
    })
    this.handleSearch(this.state.query)
  }
  handleMerge = (primary, selected) => {
    this.props.dispatch(bin.mergeBins(primary, selected))
  }
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getAll()
    })
  }
  handleRequestSort = (property, order) => {
    const orderBy = [property, order]
    this.setState({ orderBy }, () => {
      this._getAll()
    })
  }
  handleDeleteAction = async id => {
    await this.props.dispatch(bin.remove(id))
    this._getAll()
  }
  render() {
    const { list, total, fetching, fetched, history, classes } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push(`${baseUrl}/add`)}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Bin'}
          total={total}
          fetching={fetching}
          fetched={fetched}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          handleMerge={this.handleMerge}
          data={list}
          editPath={`${baseUrl}/edit/`}
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: `${baseUrl}/edit/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'merge',
              icon: <MergeTypeIcon />,
              path: `${baseUrl}/merge/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
          classes={classes}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.bin.total,
  list: state.bin.list,
  fetching: state.bin.fetching,
  fetched: state.bin.fetched,
  deleted: state.bin.deleted,
  errorMessage: state.bin.errorMessage,
  mergingBins: state.bin.mergingBins,
  mergingBinsSuccess: state.bin.mergingBinsSuccess,
  mergingBinsFailure: state.bin.mergingBinsFailure,
})

const styles = theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
})

export default withStyles(styles)(connect(mapStateToProps)(BinList))
