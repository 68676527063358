import { firebase } from '../firebase'
import {
  LOGOUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from '../../constants'

export const auth = {
  onAuthStateChanged: firebase.auth().onAuthStateChanged,
  login(values) {
    return async dispatch => {
      dispatch({
        type: LOGIN_REQUEST,
      })
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
        return dispatch({
          type: LOGIN_SUCCESS,
        })
      } catch (err) {
        return dispatch({
          type: LOGIN_FAILURE,
          meta: {},
          payload: err,
        })
      }
    }
  },
  logout() {
    return async dispatch => {
      await firebase.auth().signOut()
      dispatch({
        type: LOGOUT,
      })
    }
  },
  signup(user) {
    return async dispatch => {
      dispatch({
        type: SIGNUP_REQUEST,
      })
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(user.email, user.password)
        var newUser = firebase.auth().currentUser
        return dispatch({
          type: SIGNUP_SUCCESS,
          meta: {},
          payload: {
            user: newUser,
          },
        })
      } catch (err) {
        return dispatch({
          type: SIGNUP_FAILURE,
        })
      }
    }
  },
  requestPasswordReset(email) {
    return async dispatch => {
      dispatch({
        type: PASSWORD_RESET_REQUEST,
      })
      try {
        await firebase.auth().sendPasswordResetEmail(email)
        return dispatch({
          type: PASSWORD_RESET_SUCCESS,
          meta: {},
        })
      } catch (err) {
        return dispatch({
          type: PASSWORD_RESET_FAILURE,
          payload: err,
        })
      }
    }
  },
}
