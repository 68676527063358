import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  reduxForm,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  withStyles,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import CustomTextField from '../CustomTextField'
import CustomSnackbar from '../CustomSnackbar'
import BasicSelectField from '../BasicSelectField'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'

import { quote_request } from '../../actions'
import RegularCard from '../Cards/RegularCard.js'

import { overlay, loadingSpinner } from '../../variables/styles'
import { selects } from '../../constants'

export const fields = [
  'id',
  'bins',
  'on_account',
  'paid_status',
  'requested_pickup_date',
  'scheduled_pickup_date',
]
const baseUrl = '/quote-request'

const validate = values => {
  const errors = {}
  if (!values.business_size) {
    errors.business_size = 'Required'
  }
  if (!values.business_type) {
    errors.business_type = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.postcode) {
    errors.postcode = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving quote request' })
  }
}

class QuoteRequestEdit extends Component {
  state = {
    snackbarOpen: false,
    snackbarVariant: 'success',
    snackbarMessage: '',
    snackbarAutoHideDuration: 6000,
    dialogExitWithoutSavingOpen: false,
    popupInfo: null,
  }

  static propTypes = {
    ...reduxFormPropTypes,
    record: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    fields: PropTypes.array.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    error: PropTypes.bool,
    classes: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(quote_request.get(this.props.match.params.id))
    } else {
      this.props.clearForm()
    }
  }

  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }

  backToList = () => {
    this.props.history.push(baseUrl)
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      saving,
      submitting,
      classes,
      fetching,
      match,
    } = this.props
    const title = this.props.match.params.id ? 'Edit Quote Request' : 'Add new'
    return (
      <div>
        <Fade in={fetching || submitting}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        {match.params.id ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <CardHeader
                    title={title}
                    action={
                      <IconButton onClick={this.handleBackButton}>
                        <ArrowBackIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <div>
                      <Field
                        name="business_size"
                        component={CustomTextField}
                        label="Business Size"
                      />
                      <Field
                        name="business_type"
                        component={CustomTextField}
                        label="Business type"
                      />
                      <Field
                        name="email"
                        component={CustomTextField}
                        label="Email"
                      />
                      <Field
                        name="other_business_type"
                        component={CustomTextField}
                        label="Other business type"
                      />
                      <Field
                        name="postcode"
                        component={CustomTextField}
                        label="Postcode"
                      />
                      <Field
                        name="status"
                        component={BasicSelectField}
                        label="Status"
                        data={selects.statuses}
                        valueField="value"
                        textField="text"
                        classes={classes}
                      />
                      <Field
                        name="tel"
                        component={CustomTextField}
                        label="Telephone"
                      />
                    </div>
                  </CardContent>
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={fetching || invalid || saving}
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={handleSubmit}>
            <RegularCard
              cardTitle={'Add new business'}
              cardAction={
                <IconButton>
                  <ArrowBackIcon onClick={this.backToList} />
                </IconButton>
              }
              content={
                <div>
                  <Field
                    name="business_size"
                    component={CustomTextField}
                    label="Business Size"
                  />
                  <Field
                    name="business_type"
                    component={CustomTextField}
                    label="Business type"
                  />
                  <Field
                    name="email"
                    component={CustomTextField}
                    label="Email"
                  />
                  <Field
                    name="other_business_type"
                    component={CustomTextField}
                    label="Other business type"
                  />
                  <Field
                    name="postcode"
                    component={CustomTextField}
                    label="EX2 4LL"
                  />
                  <Field
                    name="status"
                    component={BasicSelectField}
                    label="Status"
                    data={selects.paid_statuses}
                    valueField="value"
                    textField="text"
                    classes={classes}
                  />
                  <Field
                    name="tel"
                    component={CustomTextField}
                    label="Telephone"
                  />
                </div>
              }
              footer={
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={fetching || pristine || saving}
                  className={classes.button}
                >
                  Submit
                </Button>
              }
            />
          </form>
        )}

        <CustomSnackbar
          variant={this.state.snackbarVariant}
          open={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          autoHideDuration={this.state.snackbarAutoHideDuration}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapDispatchToProps = { ...quote_request }

const mapStateToProps = state => ({
  record: state.quote_request.record,
  fetching: state.quote_request.fetching,
  fetched: state.quote_request.fetched,
  saving: state.quote_request.saving,
  saved: state.quote_request.saved,
  fields,
  initialValues: state.quote_request.record,
})

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'quote_request',
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(QuoteRequestEdit)
  )
)
