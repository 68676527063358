import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  reduxForm,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  withStyles,
  Typography,
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { formatMoney } from 'accounting'

import CustomSwitch from '../CustomSwitch'
import BasicSelectField from '../BasicSelectField'
import CustomSnackbar from '../CustomSnackbar'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'

import { collection_request } from '../../actions/collection_request.actions'

import { overlay, loadingSpinner } from '../../variables/styles'

import { selects } from '../../constants'
import DownshiftSingle from '../CustomSelect/DownshiftSingle'
import { bin as binAPI, business as businessAPI } from '../../api'
import CustomCalendar from '../CustomCalendar'

const form = 'collection-request'
const baseUrl = '/collection-request'

const validate = values => {
  const errors = {}
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving collection request' })
  }
}

class CollectionRequestEdit extends Component {
  state = {
    dialogExitWithoutSavingOpen: false,
    popupInfo: null,
  }

  static propTypes = {
    ...reduxFormPropTypes,
    record: PropTypes.object,
    clearForm: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
  }

  async componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      await this.props.get(id)
    } else {
      this.props.clearForm()
    }
  }

  getBinSuggestions = async value => {
    try {
      const results = await binAPI.search(value)
      return results.hits.length > 0
        ? results.hits.map(result => ({
            name: result.name,
            value: result.objectID,
          }))
        : []
    } catch (err) {
      console.error(err)
      return []
    }
  }
  handleClickBinLink = value => {
    this.props.history.push(`/bin/edit/${value}`)
  }

  getBusinessSuggestions = async value => {
    try {
      const results = await businessAPI.search(value)
      return results.hits.length > 0
        ? results.hits.map(result => ({
            name: result.name,
            value: result.objectID,
          }))
        : []
    } catch (err) {
      console.error(err)
      return []
    }
  }
  handleClickBusinessLink = value => {
    this.props.history.push(`/business/edit/${value}`)
  }

  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (!this.props.saved && this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }

  backToList = () => {
    this.props.history.push(baseUrl)
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const {
      record,
      handleSubmit,
      submitFailed,
      invalid,
      pristine,
      saving,
      saved,
      error,
      submitting,
      classes,
      fetching,
      match,
    } = this.props
    const title = match.params.id ? 'Edit Collection Request' : 'Add new'
    const amount = record.charge
      ? formatMoney(record.charge.amount / 100, '£')
      : '-'
    return (
      <div>
        <Fade in={fetching || submitting}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <CardHeader
                  title={title}
                  action={
                    <IconButton onClick={this.handleBackButton}>
                      <ArrowBackIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Grid container spacing={24} direction="column">
                    <Grid item>
                      <Field
                        name="on_account"
                        component={CustomSwitch}
                        label="On account"
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="paid_status"
                        component={BasicSelectField}
                        label="Paid status"
                        data={selects.paid_statuses}
                        valueField="value"
                        textField="text"
                        classes={classes}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="status"
                        component={BasicSelectField}
                        label="Status"
                        data={selects.statuses}
                        valueField="value"
                        textField="text"
                        classes={classes}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        Amount: {amount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={24}>
                        <Grid item md={6}>
                          <Typography variant="subtitle1">
                            Requested pickup date
                          </Typography>
                          <Field
                            name="requested_pickup_date"
                            component={CustomCalendar}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Typography variant="subtitle1">
                            Scheduled pickup date
                          </Typography>
                          <Field
                            name="scheduled_pickup_date"
                            component={CustomCalendar}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Field
                        name="bin"
                        component={DownshiftSingle}
                        label="Bin"
                        placeholder="Select a bin"
                        getSuggestions={this.getBinSuggestions}
                        handleClickLink={this.handleClickBinLink}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="business"
                        component={DownshiftSingle}
                        label="Business"
                        placeholder="Select a business"
                        getSuggestions={this.getBusinessSuggestions}
                        handleClickLink={this.handleClickBusinessLink}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={pristine || fetching || invalid || saving}
                    className={classes.button}
                  >
                    SAVE
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
        </Grid>

        <CustomSnackbar
          variant={submitFailed ? 'error' : 'success'}
          open={saved || Boolean(error)}
          message={error || 'Saved'}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ collection_request }) => ({
  record: collection_request.record,
  fetching: collection_request.fetching,
  fetched: collection_request.fetched,
  saving: collection_request.saving,
  saved: collection_request.saved,
  initialValues: collection_request.record,
})

const mapDispatchToProps = { ...collection_request }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form,
      onSubmit,
      validate,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(CollectionRequestEdit)
  )
)
