import {
  LOGOUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE
} from '../constants'
const initialState = {
  isSigningUp: false,
  isLoggingIn: false,
  isLoggedIn: true, // assume we're logged in
  isRequestingPasswordReset: false,
  hasRequestedPasswordReset: false,
  token: null,
  userId: null,
  error: false,
  errorMessage: ''
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isSigningUp: false,
        userId: action.payload.user.uid
      }
    case SIGNUP_FAILURE:
      return {
        ...state,
        isSigningUp: false
      }
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        error: false
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.message,
        isLoggedIn: false,
        isLoggingIn: false
      }
    case LOGOUT:
      return {
        ...state,
        error: false,
        isLoggedIn: false
      }
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        error: false,
        isRequestingPasswordReset: true,
        hasRequestedPasswordReset: false
      }
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        error: false,
        isRequestingPasswordReset: false,
        hasRequestedPasswordReset: true
      }
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isRequestingPasswordReset: false,
        hasRequestedPasswordReset: false,
        error: true,
        errorMessage: action.payload.message
      }
    default:
      return state
  }
}

export default auth
