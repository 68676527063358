import {
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEED,
  FETCH_USER_PROCESSING,
  GET_USER_FAILED,
  GET_USER_SUCCEED,
  GET_USER_PROCESSING,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCEED,
  SAVE_USER_PROCESSING,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCEED,
  DELETE_USER_PROCESSING,
} from '../../constants/user.constants'
import { db } from '../firebase'
import { startCase } from 'lodash'
import { collections } from '../../constants'
import { paginate } from '../../utils'

const dbCollection = collections.users

const list = (
  pagination,
  rowsPerPage,
  orderBy = ['displayName', 'asc'],
  filterBy = {}
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_USER_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      // set up order by
      let collectionQuery = collectionRef
      if (Object.keys(filterBy).length === 0)
        collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filter by
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value) paginateQuery = paginateQuery.where(fieldPath, '==', value)
        }
      }
      // do the limit
      const limitQuery = paginateQuery.limit(rowsPerPage)
      const docRefs = await limitQuery.get()
      const list = []
      let i = 0
      docRefs.forEach(docRef => {
        const data = docRef.data()
        const notifications = []
        for (const notificationCategory in data.notifications) {
          if (data.notifications.hasOwnProperty(notificationCategory)) {
            const notificationSubs = data.notifications[notificationCategory]
            for (const notificationSub in notificationSubs) {
              if (notificationSubs.hasOwnProperty(notificationSub)) {
                const subscribed = notificationSubs[notificationSub]
                if (subscribed) {
                  notifications.push(startCase(notificationSub))
                }
              }
            }
          }
        }
        list[i] = {
          [docRef.id]: {
            email: data.email,
            displayName: data.displayName,
            notifications: notifications.join(', '),
            role: data.role,
          },
        }
        i++
      })
      dispatch({
        type: FETCH_USER_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_USER_PROCESSING,
    })
    const collectionRef = db.collection(dbCollection)
    const docSnap = collectionRef.doc(id)
    const doc = await docSnap.get()
    if (doc.exists) {
      const docData = doc.data()
      let business = {}
      if (docData.business) {
        const businessSnap = await docData.business.get()
        if (businessSnap.exists) {
          const businessData = businessSnap.data()
          business = {
            name: businessData.name,
            value: businessSnap.id,
          }
        }
      }

      return dispatch({
        type: GET_USER_SUCCEED,
        payload: {
          record: {
            email: docData.email,
            displayName: docData.displayName,
            notifications: docData.notifications,
            id: doc.id,
            role: docData.role,
            code: docData.code,
            business,
          },
        },
      })
    } else {
      dispatch({
        type: GET_USER_FAILED,
        payload: {
          error: `No such collection request: ${id}`,
        },
      })
    }
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_USER_PROCESSING,
    })

    let business = null

    if (values.business.value) {
      const businessRef = db.collection(collections.businesses)
      const businessSnap = await businessRef.doc(values.business.value).get()
      if (businessSnap.exists) {
        business = businessSnap.ref
      }
    }

    const data = {
      ...values,
      business,
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(data, { merge: true })
      } else {
        await db.collection(dbCollection).add(data)
      }
      return dispatch({
        type: SAVE_USER_SUCCEED,
      })
    } catch (err) {
      dispatch({
        type: SAVE_USER_FAILED,
        payload: {
          error: 'Error saving user',
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_USER_PROCESSING,
    })
    try {
      for (const id of ids) {
        await db
          .collection(dbCollection)
          .doc(id)
          .delete()
      }
      dispatch({
        type: DELETE_USER_SUCCEED,
      })
    } catch (err) {
      dispatch({
        type: DELETE_USER_FAILED,
        payload: err.message,
      })
    }
  }
}

export const user = {
  list,
  get,
  save,
  remove,
}
