
/**
 * Shorten adddress
 * @param {object} address An address object to parse
 * @param {number} trunc An amount to truncate the address by
 */
export function shortenAddress(address, trunc = 100) {
  address = address
    ? Object.values(address)
        .filter(val => val !== '')
        .join(', ')
    : ''
  if (address.length > trunc && address.length > 0)
    address = address.substring(0, trunc) + '…'
  return address
}

export async function paginate(query, collectionRef, pagination) {
  if (pagination && pagination.id) {
    let doc = {}
    try {
      doc = await collectionRef
      .doc(pagination.id)
      .get()
    } catch (err) {
      throw new Error(err)
    }
    if (pagination.direction === -1) {
      return query.endBefore(doc)
    } else {
      return query.startAfter(doc)
    }
  } else return query
}
