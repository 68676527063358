import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  withStyles,
  colors,
} from '@material-ui/core'
import {
  // FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  // LastPage as LastPageIcon,
} from '@material-ui/icons'

import Image from '../../utils/GracefulImage'

import { overlay, loadingSpinner } from '../../variables/styles'
import EnhancedTableToolbar from './EnhancedTableToolbar'

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
})

class SelectedItems extends Component {
  static propTypes = {
    selected: PropTypes.array,
    data: PropTypes.array.isRequired,
    tableHead: PropTypes.array.isRequired,
    value: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
  }
  render() {
    const { selected, data, tableHead, value, handleChange } = this.props
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Items to merge - select primary
        </FormLabel>
        <RadioGroup
          name="merge-select-primary"
          value={value}
          onChange={handleChange}
        >
          {data
            .filter(n => {
              const rowKey = Object.keys(n)[0]
              return selected.indexOf(rowKey) > -1
            })
            .map(n => {
              const rowKey = Object.keys(n)[0]
              const itemTitle = n[rowKey][tableHead[0].id]
              return (
                <FormControlLabel
                  key={rowKey}
                  value={rowKey}
                  control={<Radio color="primary" />}
                  label={itemTitle}
                />
              )
            })}
        </RadioGroup>
      </FormControl>
    )
  }
}

class TablePaginationActions extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
  }

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    )
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        {/* <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton> */}
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        {/* <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton> */}
      </div>
    )
  }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions)

class EnhancedTableHead extends Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columnData: PropTypes.array,
  }

  state = {
    selected: {},
  }

  componentDidMount() {
    for (const column of this.props.columnData) {
      if (column.select) {
        this.setState({
          ...this.state,
          selected: {
            ...this.state.selected,
            [column.id]: {
              value: '',
            },
          },
        })
      }
    }
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  handleSelectChange = column => event => {
    this.setState(
      {
        ...this.state,
        selected: {
          ...this.state.selected,
          [column.id]: {
            value: event.target.value,
          },
        },
      },
      column.select.handleChange(event.target.value)
    )
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columnData,
    } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                align={column.type === 'number' ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.enableSort ? (
                  <Tooltip
                    title="Sort"
                    placement={'bottom-end'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  column.label
                )}
                {column.select && this.state.selected[column.id] ? (
                  <Select
                    value={this.state.selected[column.id].value}
                    onChange={this.handleSelectChange(column)}
                    inputProps={{
                      name: column.id,
                      id: `${column.id}-simple`,
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {column.select &&
                      column.select.data.map((datum, i) => (
                        <MenuItem
                          value={datum[column.select.valueField]}
                          key={i}
                        >
                          {datum[column.select.textField]}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  ''
                )}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 200,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  button: {
    marginRight: 10,
  },
  search: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px',
      float: 'none !important',
      paddingTop: '1px',
      paddingBottom: '1px',
      padding: '10px 15px',
      width: 'auto',
      marginTop: '40px',
    },
  },
  success: {
    backgroundColor: colors.green[600],
  },
  loadingSpinner,
  overlay,
})

class CustomTable extends Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    selected: [],
    data: this.props.data,
    rowsPerPage: this.props.rowsPerPage,
    page: this.props.page,
    open: false,
    deleteIds: null,
    anchorEl: null,
    selectedRow: null,
    mergeDialogOpen: false,
    duplicateDialogOpen: false,
    mergeDialogPrimarySelected: null,
  }

  handleClickMenu = (rowKey, event) => {
    this.setState({ selectedRow: rowKey, anchorEl: event.currentTarget })
  }

  handleClickRow = id => event => {
    id = id || null
    if (this.props.editPath) {
      let url = this.props.editPath + (id ? `${id}` : '')
      this.props.history.push(url)
    }
  }

  handleContextMenu = id => event => {
    event.preventDefault()
    this.handleClickMenu(id, event)
  }

  handleCloseMenu = () => {
    this.setState({ selectedRow: null, anchorEl: null })
  }

  handleClickDelete = () => {
    this.setState({ open: true, deleteIds: this.state.selected })
  }

  handleClose = () => {
    this.setState({ open: false, deleteIds: null })
  }

  handleDelete = () => {
    let objectIds = this.state.deleteIds.slice()
    let that = this
    this.setState({ open: false, deleteIds: null, selected: [] }, () => {
      that.props.handleDelete(objectIds)
    })
  }

  handleClickMerge = () => () => {
    if (this.state.selected.length < 2) return
    else {
      this.setState({ mergeDialogOpen: true })
    }
  }

  handleClickDuplicate = () => () => {
    if (this.state.selected.length !== 1) return
    else {
      this.setState({ duplicateDialogOpen: true })
    }
  }
  handleDuplicateDialogClose = () => {
    this.setState({ duplicateDialogOpen: false })
  }

  handleMergeDialogPrimaryChange = event => {
    this.setState({ mergeDialogPrimarySelected: event.target.value })
  }

  handleMergeDialogClose = () => {
    this.setState({ mergeDialogOpen: false, mergeDialogPrimarySelected: null })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    if (this.props.handleRequestSort) {
      this.props.handleRequestSort(property, order)
      this.setState({ order, orderBy })
    } else {
      const data =
        order === 'desc'
          ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
          : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
      this.setState({ data, order, orderBy })
    }
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.props.data.map(n => Object.keys(n)[0]) })
      return
    }
    this.setState({ selected: [] })
  }

  handleClickCheckbox = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, page) => {
    this.props.handleChangePage(page)
  }

  handleChangeRowsPerPage = event => {
    this.props.handleChangeRowsPerPage(event.target.value)
  }

  handleAction = (path, id) => {
    id = id || null
    let url = path + (id ? `${id}` : '')
    this.props.history.push(url)
  }

  handleSearch = this.props.handleSearch
    ? event => {
        this.props.handleSearch(event.target.value)
      }
    : null

  handleMerge = () => {
    const selectionMinusPrimary = this.state.selected.filter(
      sel => sel !== this.state.mergeDialogPrimarySelected
    )
    this.props.handleMerge(
      this.state.mergeDialogPrimarySelected,
      selectionMinusPrimary
    )
  }

  handleDuplicate = () => {}

  renderTableCellContent = (content, type = 'text', key) => {
    switch (type) {
      case 'address':
        return JSON.stringify(content)
      case 'image':
        return <Image src={content.url()} alt={key} style={{ maxWidth: 128 }} />
      case 'checkbox':
        return <Checkbox disabled value={key} checked={content} />
      default:
        return content
    }
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const {
      fetching,
      classes,
      tableHead,
      tableHeaderColor,
      data,
      title,
      actions,
      searchInput = '',
      rowsPerPage,
      page,
      total,
      merging,
      mergeSuccess,
      mergeFailure,
      duplicateForm,
      duplicateSuccess,
      duplicateFailure,
    } = this.props
    const { order, orderBy, selected, anchorEl } = this.state
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <Fade in={fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          actions={actions}
          handleSearch={this.handleSearch}
          searchInput={searchInput}
          extraSearchElements={this.props.extraSearchElements}
          handleClickDelete={this.handleClickDelete}
          handleClickMerge={this.handleClickMerge}
          handleClickDuplicate={this.handleClickDuplicate}
          merging={merging}
          count={total}
          enableDuplicate={duplicateForm ? true : false}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <EnhancedTableHead
                backgroundColor={tableHeaderColor}
                columnData={tableHead}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                actions={actions}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
            ) : null}
            <TableBody>
              {data.map(n => {
                const rowKey = Object.keys(n)[0]
                const rowValue = n[Object.keys(n)[0]]
                const isSelected = this.isSelected(rowKey)
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={rowKey}
                    selected={isSelected}
                    aria-owns={anchorEl ? `row-menu` : null}
                    aria-haspopup="true"
                    onContextMenu={this.handleContextMenu(rowKey)}
                  >
                    <TableCell padding="checkbox" key={'check'}>
                      <Checkbox
                        checked={isSelected}
                        onClick={event =>
                          this.handleClickCheckbox(event, rowKey)
                        }
                      />
                    </TableCell>
                    {tableHead.map(field => {
                      return (
                        <TableCell
                          align={field.type === 'number' ? 'right' : 'left'}
                          key={field.id}
                          padding="dense"
                          className={classes.tableCell}
                          onClick={this.handleClickRow(rowKey)}
                        >
                          {this.renderTableCellContent(
                            rowValue[field.id],
                            field.type,
                            rowKey
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={10000}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  labelDisplayedRows={() => ''}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action is permanent
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                No
              </Button>
              <Button
                onClick={() => this.handleDelete()}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.mergeDialogOpen && !mergeFailure && !mergeSuccess}
            onClose={this.handleMergeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Merge these items?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to merge these items? This cannot be undone.
              </DialogContentText>
              <SelectedItems
                selected={selected}
                data={data}
                tableHead={tableHead}
                value={this.state.mergeDialogPrimarySelected}
                handleChange={this.handleMergeDialogPrimaryChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleMergeDialogClose()}
                color="primary"
              >
                No
              </Button>
              <Button
                onClick={() => this.handleMerge()}
                color="primary"
                autoFocus
                disabled={!this.state.mergeDialogPrimarySelected}
              >
                Yes
              </Button>
            </DialogActions>
            {merging ? <LinearProgress /> : ''}
          </Dialog>
          {duplicateForm ? (
            <Dialog
              open={
                this.state.duplicateDialogOpen &&
                !duplicateFailure &&
                !duplicateSuccess
              }
              onClose={this.handleDuplicateDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md"
            >
              <DialogContent>
                {React.cloneElement(duplicateForm, {
                  duplicateId: selected[0],
                  duplicatedSuccess: this.handleDuplicate,
                  closeDuplicate: this.handleDuplicateDialogClose,
                })}
              </DialogContent>
            </Dialog>
          ) : null}

          <Snackbar
            className={classes.success}
            open={mergeSuccess}
            autoHideDuration={6000}
            message={<span id="message-id">Successfully merged</span>}
          />
          {actions !== undefined ? (
            <Menu
              id={`row-menu`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMenu}
            >
              {actions !== undefined ? (
                <MenuItem key={'action'}>
                  {actions.map(function(action) {
                    if (action.label === 'delete') {
                      return (
                        <Button
                          key={`${action.label}`}
                          variant="flat"
                          mini
                          onClick={() =>
                            this.handleClickDelete(this.state.selected)
                          }
                          color={action.color}
                          aria-label={action.label}
                          className={classes.button}
                        >
                          {action.icon}
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          key={`${action.label}`}
                          variant="flat"
                          mini
                          onClick={() =>
                            this.handleAction(
                              action.path,
                              this.state.selectedRow
                            )
                          }
                          color={action.color}
                          aria-label={action.label}
                          className={classes.button}
                        >
                          {action.icon}
                        </Button>
                      )
                    }
                  }, this)}
                </MenuItem>
              ) : null}
            </Menu>
          ) : null}
        </div>
      </Paper>
    )
  }
}

CustomTable.propTypes = {
  fetching: PropTypes.bool,
  fetched: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  tableHead: PropTypes.array.isRequired,
  tableHeaderColor: PropTypes.string,
  data: PropTypes.array,
  title: PropTypes.string.isRequired,
  editPath: PropTypes.string,
  history: PropTypes.object,
  actions: PropTypes.array,
  searchInput: PropTypes.string,
  extraSearchElements: PropTypes.object,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number,
  merging: PropTypes.bool,
  mergeSuccess: PropTypes.func,
  mergeFailure: PropTypes.func,
  duplicateForm: PropTypes.element,
  duplicateSuccess: PropTypes.func,
  duplicateFailure: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleRequestSort: PropTypes.func,
  handleSearch: PropTypes.func,
  handleMerge: PropTypes.func,
}

export default withStyles(styles)(CustomTable)
