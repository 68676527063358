export const COUNT_BINBAG_ROLL_PROCESSING = 'COUNT_BINBAG_ROLL_PROCESSING'
export const COUNT_BINBAG_ROLL_SUCCEED = 'COUNT_BINBAG_ROLL_SUCCEED'
export const COUNT_BINBAG_ROLL_FAILED = 'COUNT_BINBAG_ROLL_FAILED'
export const FETCH_BINBAG_ROLL_PROCESSING = 'FETCH_BINBAG_ROLL_PROCESSING'
export const FETCH_BINBAG_ROLL_SUCCEED = 'FETCH_BINBAG_ROLL_SUCCEED'
export const FETCH_BINBAG_ROLL_FAILED = 'FETCH_BINBAG_ROLL_FAILED'
export const GET_BINBAG_ROLL_PROCESSING = 'GET_BINBAG_ROLL_PROCESSING'
export const GET_BINBAG_ROLL_SUCCEED = 'GET_BINBAG_ROLL_SUCCEED'
export const GET_BINBAG_ROLL_FAILED = 'GET_BINBAG_ROLL_FAILED'
export const SAVE_BINBAG_ROLL_PROCESSING = 'SAVE_BINBAG_ROLL_PROCESSING'
export const SAVE_BINBAG_ROLL_SUCCEED = 'SAVE_BINBAG_ROLL_SUCCEED'
export const SAVE_BINBAG_ROLL_FAILED = 'SAVE_BINBAG_ROLL_FAILED'
export const DELETE_BINBAG_ROLL_PROCESSING = 'DELETE_BINBAG_ROLL_PROCESSING'
export const DELETE_BINBAG_ROLL_SUCCEED = 'DELETE_BINBAG_ROLL_SUCCEED'
export const DELETE_BINBAG_ROLL_FAILED = 'DELETE_BINBAG_ROLL_FAILED'
export const CLEAR_FORM = 'CLEAR_FORM'
