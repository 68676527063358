import BinList from '../components/Bin/BinList'
import BinEdit from '../components/Bin/BinEdit'
import BinLocationList from '../components/BinLocation/BinLocationList'
import BinLocationEdit from '../components/BinLocation/BinLocationEdit'
import BusinessList from '../components/Business/BusinessList'
import BusinessEdit from '../components/Business/BusinessEdit'
import CollectionRequestList from '../components/CollectionRequest/CollectionRequestList'
import CollectionRequestEdit from '../components/CollectionRequest/CollectionRequestEdit'
import QuoteRequestEdit from '../components/QuoteRequest/QuoteRequestEdit'
import QuoteRequestList from '../components/QuoteRequest/QuoteRequestList'
import BinbagRequestEdit from '../components/BinbagRequest/BinbagRequestEdit'
import BinbagRequestList from '../components/BinbagRequest/BinbagRequestList'
import BinbagRollEdit from '../components/BinbagRoll/BinbagRollEdit'
import BinbagRollList from '../components/BinbagRoll/BinbagRollList'
import UserList from '../components/User/UserList'
import UserEdit from '../components/User/UserEdit'
import {
  Delete as DeleteIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  PanoramaFishEye,
  People as PeopleIcon,
  LocationOnOutlined,
  BusinessCenter,
  LocalShipping,
  Face as FaceIcon,
} from '@material-ui/icons'

const appRoutes = [
  {
    path: '/bin',
    sidebarName: 'bin',
    navbarName: 'bin',
    icon: DeleteIcon,
    component: BinList,
    routes: [
      {
        action: 'add',
        path: '/bin/add',
        component: BinEdit,
      },
      {
        action: 'edit',
        path: '/bin/edit/:id',
        component: BinEdit,
      },
      {
        action: 'delete',
        path: '/bin/delete/:id',
        component: BinList,
      },
    ],
  },
  {
    path: '/bin-location',
    sidebarName: 'bin location',
    navbarName: 'bin location',
    icon: LocationOnOutlined,
    component: BinLocationList,
    routes: [
      {
        action: 'add',
        path: '/bin-location/add',
        component: BinLocationEdit,
      },
      {
        action: 'edit',
        path: '/bin-location/edit/:id',
        component: BinLocationEdit,
      },
      {
        action: 'delete',
        path: '/bin-location/delete/:id',
        component: BinLocationList,
      },
    ],
  },
  {
    path: '/business',
    sidebarName: 'business',
    navbarName: 'business',
    icon: BusinessCenter,
    component: BusinessList,
    routes: [
      {
        action: 'add',
        path: '/business/add',
        component: BusinessEdit,
      },
      {
        action: 'edit',
        path: '/business/edit/:id',
        component: BusinessEdit,
      },
      {
        action: 'delete',
        path: '/business/delete/:id',
        component: BusinessList,
      },
    ],
  },  
  {
    path: '/collection-request',
    sidebarName: 'collection request',
    navbarName: 'collection request',
    icon: LocalShipping,
    component: CollectionRequestList,
    routes: [
      {
        action: 'add',
        path: '/collection-request/add',
        component: CollectionRequestEdit,
      },
      {
        action: 'edit',
        path: '/collection-request/edit/:id',
        component: CollectionRequestEdit,
      },
      {
        action: 'delete',
        path: '/collection-request/delete/:id',
        component: CollectionRequestList,
      },
    ],
  },
  {
    path: '/quote-request',
    sidebarName: 'quote request',
    navbarName: 'quote request',
    icon: FaceIcon,
    component: QuoteRequestList,
    routes: [
      {
        action: 'add',
        path: '/quote-request/add',
        component: QuoteRequestEdit,
      },
      {
        action: 'edit',
        path: '/quote-request/edit/:id',
        component: QuoteRequestEdit,
      },
      {
        action: 'delete',
        path: '/quote-request/delete/:id',
        component: QuoteRequestList,
      },
    ],
  },
  {
    path: '/binbag-request',
    sidebarName: 'binbag request',
    navbarName: 'binbag request',
    icon: DeleteOutlinedIcon,
    component: BinbagRequestList,
    routes: [
      {
        action: 'add',
        path: '/binbag-request/add',
        component: BinbagRequestEdit,
      },
      {
        action: 'edit',
        path: '/binbag-request/edit/:id',
        component: BinbagRequestEdit,
      },
      {
        action: 'delete',
        path: '/binbag-request/delete/:id',
        component: BinbagRequestList,
      },
    ],
  },
  {
    path: '/binbag-roll',
    sidebarName: 'binbag roll',
    navbarName: 'binbag roll',
    icon: PanoramaFishEye,
    component: BinbagRollList,
    routes: [
      {
        action: 'add',
        path: '/binbag-roll/add',
        component: BinbagRollEdit,
      },
      {
        action: 'edit',
        path: '/binbag-roll/edit/:id',
        component: BinbagRollEdit,
      },
      {
        action: 'delete',
        path: '/binbag-roll/delete/:id',
        component: BinbagRollList,
      },
    ],
  },
  {
    path: '/user',
    sidebarName: 'user',
    navbarName: 'user',
    icon: PeopleIcon,
    component: UserList,
    routes: [
      {
        action: 'add',
        path: '/user/add',
        component: UserEdit,
      },
      {
        action: 'edit',
        path: '/user/edit/:id',
        component: UserEdit,
      },
      {
        action: 'delete',
        path: '/user/delete/:id',
        component: UserList,
      },
    ],
    breakLine: true,
  },
  {
    redirect: true,
    path: '/',
    to: '/dashboard',
    navbarName: 'Redirect',
  },
]

export default appRoutes
