export const COUNT_BIN_LOCATION_PROCESSING = 'COUNT_BIN_LOCATION_PROCESSING'
export const COUNT_BIN_LOCATION_SUCCEED = 'COUNT_BIN_LOCATION_SUCCEED'
export const COUNT_BIN_LOCATION_FAILED = 'COUNT_BIN_LOCATION_FAILED'
export const FETCH_BIN_LOCATION_PROCESSING = 'FETCH_BIN_LOCATION_PROCESSING'
export const FETCH_BIN_LOCATION_SUCCEED = 'FETCH_BIN_LOCATION_SUCCEED'
export const FETCH_BIN_LOCATION_FAILED = 'FETCH_BIN_LOCATION_FAILED'
export const GET_BIN_LOCATION_PROCESSING = 'GET_BIN_LOCATION_PROCESSING'
export const GET_BIN_LOCATION_SUCCEED = 'GET_BIN_LOCATION_SUCCEED'
export const GET_BIN_LOCATION_FAILED = 'GET_BIN_LOCATION_FAILED'
export const FETCH_BIN_LOCATION_GENUS_FAILED = 'FETCH_BIN_LOCATION_GENUS_FAILED'
export const SAVE_BIN_LOCATION_PROCESSING = 'SAVE_BIN_LOCATION_PROCESSING'
export const SAVE_BIN_LOCATION_SUCCEED = 'SAVE_BIN_LOCATION_SUCCEED'
export const SAVE_BIN_LOCATION_FAILED = 'SAVE_BIN_LOCATION_FAILED'
export const DELETE_BIN_LOCATION_PROCESSING = 'DELETE_BIN_LOCATION_PROCESSING'
export const DELETE_BIN_LOCATION_SUCCEED = 'DELETE_BIN_LOCATION_SUCCEED'
export const DELETE_BIN_LOCATION_FAILED = 'DELETE_BIN_LOCATION_FAILED'
export const ADD_BIN_LOCATION_BUSINESS = 'ADD_BIN_LOCATION_BUSINESS'
export const REMOVE_BIN_LOCATION_BUSINESS = 'REMOVE_BIN_LOCATION_BUSINESS'
export const UPDATE_BIN_LOCATION_LAT_LNG = 'UPDATE_BIN_LOCATION_LAT_LNG'
export const GET_BINS_FOR_BIN_LOCATION_PROCESSING =
  'GET_BINS_FOR_BIN_LOCATION_PROCESSING'
export const GET_BINS_FOR_BIN_LOCATION_SUCCEED =
  'GET_BINS_FOR_BIN_LOCATION_SUCCEED'
export const CLEAR_BIN_LOCATION_FORM = 'CLEAR_BIN_LOCATION_FORM'
