import { auth as authAPI } from '../api'
import { firebase } from '../api/firebase'

export const auth = {
  onAuthStateChanged: callback => firebase.auth().onAuthStateChanged(callback),
  login(values) {
    return dispatch => {
      return dispatch(authAPI.login(values))
    }
  },
  logout() {
    return dispatch => {
      return dispatch(authAPI.logout())
    }
  },
  signup(values) {
    return dispatch => {
      return dispatch(authAPI.signup(values))
    }
  },
  requestPasswordReset(email) {
    return dispatch => {
      return dispatch(authAPI.requestPasswordReset(email))
    }
  }
}
