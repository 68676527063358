import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  reduxForm,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Fade,
  withStyles,
} from '@material-ui/core'
import { deburr } from 'lodash'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import CustomSnackbar from '../CustomSnackbar'
import BasicSelectField from '../BasicSelectField'
import DialogCloseWithoutSaving from '../DialogCloseWithoutSaving'

import { binbag_request } from '../../actions'
import { business as businessAPI } from '../../api/modules/business.api'

import { overlay, loadingSpinner } from '../../variables/styles'

import { selects, defaultLatLng } from '../../constants'
import CustomSwitch from '../CustomSwitch'
import CustomTextField from '../CustomTextField'
import DownshiftSingle from '../CustomSelect/DownshiftSingle'

export const fields = [
  'id',
  'bins',
  'on_account',
  'paid_status',
  'requested_pickup_date',
  'scheduled_pickup_date',
]
const baseUrl = '/binbag-request'

const validate = values => {
  const errors = {}
  if (!values.business_size) {
    errors.business_size = 'Required'
  }
  if (!values.business_type) {
    errors.business_type = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.postcode) {
    errors.postcode = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.save(values)
    props.history.push(baseUrl)
  } catch (err) {
    throw new SubmissionError({ _error: 'Problem saving binbag request' })
  }
}

class BinbagRequestEdit extends Component {
  state = {
    dialogExitWithoutSavingOpen: false,
    popupInfo: null,
    viewport: {
      width: '100%',
      height: 250,
      ...defaultLatLng,
      zoom: 12,
    },
  }

  static propTypes = {
    ...reduxFormPropTypes,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(binbag_request.get(this.props.match.params.id))
    } else {
      this.props.clearForm()
    }
  }

  getBinbagSuggestions(value) {
    // TODO: Must implement algolia
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    return inputLength === 0
      ? []
      : [].filter(suggestion => {
          const keep =
            count < 5 &&
            suggestion.label.slice(0, inputLength).toLowerCase() === inputValue

          if (keep) {
            count += 1
          }

          return keep
        })
  }

  getBusinessSuggestions = async value => {
    try {
      const results = await businessAPI.search(value)
      return results.hits.length > 0
        ? results.hits.map(result => ({
            name: result.name,
            value: result.objectID,
          }))
        : []
    } catch (err) {
      console.error(err)
      return []
    }
  }
  handleClickBusinessLink = value => {
    this.props.history.push(`/business/edit/${value}`)
  }

  handleDismissExitWithoutSaving = () => {
    this.setState({
      dialogExitWithoutSavingOpen: false,
    })
  }
  handleExitWithoutSaving = () => {
    this.backToList()
  }
  handleExitWithSaving = async () => {
    await this.props.handleSubmit()
    this.backToList()
  }
  handleBackButton = () => {
    if (this.props.dirty) {
      this.setState({
        dialogExitWithoutSavingOpen: true,
      })
    } else {
      this.backToList()
    }
  }

  backToList = () => {
    this.props.history.push(baseUrl)
  }
  hideAlert = event => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const {
      submitFailed,
      saved,
      error,
      invalid,
      saving,
      submitting,
      classes,
      fetching,
    } = this.props
    const title = this.props.match.params.id ? 'Edit Binbag Request' : 'Add new'
    return (
      <div>
        <Fade in={fetching || submitting}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : -1 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <form noValidate autoComplete="off">
                <CardHeader
                  title={title}
                  action={
                    <IconButton onClick={this.handleBackButton}>
                      <ArrowBackIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Grid container spacing={24} direction="column">
                    <Grid item>
                      <Field
                        name="on_account"
                        component={CustomSwitch}
                        label="On account"
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="amount"
                        component={CustomTextField}
                        label="Amount"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="paid_status"
                        component={BasicSelectField}
                        label="Paid status"
                        data={selects.paid_statuses}
                        valueField="value"
                        textField="text"
                        classes={classes}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="quantity"
                        component={CustomTextField}
                        type="number"
                        label="Quantity"
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="status"
                        component={BasicSelectField}
                        label="Status"
                        data={selects.statuses}
                        valueField="value"
                        textField="text"
                        classes={classes}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="type"
                        component={BasicSelectField}
                        label="Type"
                        data={selects.binbag_types}
                        valueField="value"
                        textField="text"
                        classes={classes}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="business"
                        component={DownshiftSingle}
                        label="Business"
                        placeholder="Select a business"
                        getSuggestions={this.getBusinessSuggestions}
                        handleClickLink={this.handleClickBusinessLink}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="test"
                        component={CustomSwitch}
                        label="Test"
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={fetching || invalid || saving}
                    className={classes.button}
                  >
                    SAVE
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
        </Grid>

        <CustomSnackbar
          variant={submitFailed ? 'error' : 'success'}
          open={saved || Boolean(error)}
          message={error || 'Saved'}
          handleClose={this.hideAlert}
        />

        <DialogCloseWithoutSaving
          dialogExitWithoutSavingOpen={this.state.dialogExitWithoutSavingOpen}
          handleExitWithSaving={this.handleExitWithSaving}
          handleExitWithoutSaving={this.handleExitWithoutSaving}
          handleDismissExitWithoutSaving={this.handleDismissExitWithoutSaving}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  fetching: state.binbag_request.fetching,
  fetched: state.binbag_request.fetched,
  saving: state.binbag_request.saving,
  saved: state.binbag_request.saved,
  fields,
  initialValues: state.binbag_request.record,
})

const mapDispatchToProps = { ...binbag_request }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  h5: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'binbag_request',
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
    })(BinbagRequestEdit)
  )
)
