import {
  FETCH_BUSINESS_FAILED,
  FETCH_BUSINESS_SUCCEED,
  FETCH_BUSINESS_PROCESSING,
  GET_BUSINESS_FAILED,
  GET_BUSINESS_SUCCEED,
  GET_BUSINESS_PROCESSING,
  SAVE_BUSINESS_FAILED,
  SAVE_BUSINESS_SUCCEED,
  SAVE_BUSINESS_PROCESSING,
  DELETE_BUSINESS_FAILED,
  DELETE_BUSINESS_SUCCEED,
  DELETE_BUSINESS_PROCESSING,
  GET_BIN_LOCATIONS_FOR_BUSINESS_PROCESSING,
  GET_BIN_LOCATIONS_FOR_BUSINESS_SUCCESS,
} from '../../constants/business.constants'
import { collections } from '../../constants'
import { db } from '../firebase'
import algolia from 'algoliasearch'
import debounce from 'p-debounce'
import { shortenAddress, paginate } from '../../utils'
import { selects } from '../../constants'
const algoliaClient = algolia(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
)

const dbCollection = 'businesses'
// index name should be the same as dbCollection
const searchIndex = algoliaClient.initIndex(dbCollection)

const search = debounce((query, rowsPerPage = 10) => {
  // do the limit
  return searchIndex.search({
    query,
    hitsPerPage: rowsPerPage,
  })
}, 500)

const searchList = (query, rowsPerPage = 10) => {
  return async dispatch => {
    // do the limit
    const results = await searchIndex.search({
      query,
      hitsPerPage: rowsPerPage,
    })
    let i = 0
    const list = []
    for (let hit of results.hits) {
      const business_types = hit.business_types
        ? hit.business_types
            .map(business_type => {
              const bt = selects.business_types.find(
                bt => bt.value === business_type
              )
              return bt ? bt.text : business_type
            })
            .join(', ')
        : ''
      list[i] = {
        [hit.objectID]: {
          name: hit.name,
          address: shortenAddress(hit.address),
          business_types,
        },
      }
      i++
    }
    return dispatch({
      type: FETCH_BUSINESS_SUCCEED,
      payload: { list },
    })
  }
}

const list = (
  pagination,
  rowsPerPage = 10,
  orderBy = ['name', 'asc'],
  filterBy
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_BUSINESS_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      // set up order by
      const collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filter by
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value)
            paginateQuery = paginateQuery.where(
              fieldPath,
              'array-contains',
              value
            )
        }
      }
      // do the limit
      const limitQuery = paginateQuery.limit(rowsPerPage)
      const businesses = await limitQuery.get()
      const list = []
      let i = 0
      for (const business of businesses.docs) {
        const businessData = business.data()
        let address = shortenAddress(businessData.address)
        const business_types = businessData.business_types
          ? businessData.business_types
              .map(business_type => {
                const bt = selects.business_types.find(
                  bt => bt.value === business_type
                )
                return bt ? bt.text : business_type
              })
              .join(', ')
          : ''
        list[i] = {
          [business.id]: {
            name: businessData.name,
            address,
            business_types,
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_BUSINESS_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_BUSINESS_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const getBinLocationsForBusiness = id => {
  return async dispatch => {
    dispatch({
      type: GET_BIN_LOCATIONS_FOR_BUSINESS_PROCESSING,
    })
    const businessSnap = await db
      .collection(dbCollection)
      .doc(id)
      .get()
    const binLocationRef = db.collection(collections.bin_locations)
    const binLocationSnaps = await binLocationRef
      .where('businesses', 'array-contains', businessSnap.ref)
      .get()
    let bin_locations = []
    for (const binLocationSnap of binLocationSnaps.docs) {
      const binLocationData = binLocationSnap.data()
      bin_locations.push({
        name: binLocationData.name,
        value: binLocationSnap.id,
      })
    }
    dispatch({
      type: GET_BIN_LOCATIONS_FOR_BUSINESS_SUCCESS,
      payload: bin_locations,
    })
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_BUSINESS_PROCESSING,
    })
    const collectionRef = db.collection(dbCollection)
    const docRef = collectionRef.doc(id)
    const businessRef = await docRef.get()
    if (businessRef.exists) {
      const businessData = businessRef.data()
      dispatch({
        type: GET_BUSINESS_SUCCEED,
        payload: {
          record: {
            ...businessData,
            business_types: businessData.business_types,
            id: businessRef.id,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BUSINESS_FAILED,
        payload: {
          error: `No such business: ${id}`,
        },
      })
    }
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_BUSINESS_PROCESSING,
    })

    const data = {
      ...values,
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(data, { merge: true })
      } else {
        await db.collection(dbCollection).add(data)
      }
      return dispatch({
        type: SAVE_BUSINESS_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: SAVE_BUSINESS_FAILED,
        payload: {
          error: `Error: ${err.message}`,
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_BUSINESS_PROCESSING,
    })
    try {
      const promises = []
      for (const id of ids) {
        promises.push(
          db
            .collection(dbCollection)
            .doc(id)
            .delete()
        )
      }
      await Promise.all(promises)
      return dispatch({
        type: DELETE_BUSINESS_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_BUSINESS_FAILED,
      })
    }
  }
}

export const business = {
  search,
  searchList,
  getBinLocationsForBusiness,
  list,
  get,
  save,
  remove,
}
