import {
  FETCH_BIN_LOCATION_FAILED,
  FETCH_BIN_LOCATION_SUCCEED,
  FETCH_BIN_LOCATION_PROCESSING,
  GET_BIN_LOCATION_FAILED,
  GET_BIN_LOCATION_SUCCEED,
  GET_BIN_LOCATION_PROCESSING,
  SAVE_BIN_LOCATION_FAILED,
  SAVE_BIN_LOCATION_SUCCEED,
  SAVE_BIN_LOCATION_PROCESSING,
  DELETE_BIN_LOCATION_FAILED,
  DELETE_BIN_LOCATION_SUCCEED,
  DELETE_BIN_LOCATION_PROCESSING,
  GET_BINS_FOR_BIN_LOCATION_PROCESSING,
  GET_BINS_FOR_BIN_LOCATION_SUCCEED,
  defaultLatLng,
  collections,
} from '../../constants'
import { db, firebase } from '../firebase'
import algolia from 'algoliasearch'
import debounce from 'p-debounce'
import { paginate } from '../../utils'
const algoliaClient = algolia(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
)

const dbCollection = 'bin_locations'
// index name should be the same as dbCollection
const searchIndex = algoliaClient.initIndex(dbCollection)

const search = debounce((query, rowsPerPage = 10) => {
  // do the limit
  return searchIndex.search({
    query,
    hitsPerPage: rowsPerPage,
  })
}, 500)

const searchList = (query, rowsPerPage = 10) => {
  return async dispatch => {
    // do the limit
    const results = await searchIndex.search({
      query,
      hitsPerPage: rowsPerPage,
    })
    let i = 0
    const list = []
    for (let hit of results.hits) {
      const businesses = hit.businesses ? hit.businesses.join(', ') : ''
      list[i] = {
        [hit.objectID]: {
          name: hit.name,
          businesses,
        },
      }
      i++
    }
    return dispatch({
      type: FETCH_BIN_LOCATION_SUCCEED,
      payload: { list },
    })
  }
}

const _getBinLocationData = async binLocationSnap => {
  const binLocationData = binLocationSnap.data()
  const businesses = []
  const businessPromises = []
  if (binLocationData.businesses) {
    binLocationData.businesses.forEach(business => {
      businessPromises.push(business.get())
    })
    const results = await Promise.all(businessPromises)
    for (const businessRef of results) {
      if (businessRef.exists) {
        const businessData = businessRef.data()
        businesses.push({ value: businessRef.id, name: businessData.name })
      }
    }
  }
  return {
    id: binLocationSnap.id,
    businesses,
    name: binLocationData.name,
    address: binLocationData.address,
    loc: binLocationData.loc
      ? {
          latitude: binLocationData.loc.latitude,
          longitude: binLocationData.loc.longitude,
        }
      : defaultLatLng,
  }
}

const list = (
  pagination,
  rowsPerPage = 10,
  orderBy = ['name', 'asc'],
  filterBy
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_BIN_LOCATION_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection) // set up order by
      // set up order by
      const collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filter by
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value) paginateQuery.where(fieldPath, 'array-contains', value)
        }
      }
      // do the limit
      paginateQuery.limit(rowsPerPage)
      const bin_locations = await paginateQuery.get()
      const list = []
      const binLocationPromises = []
      bin_locations.forEach(bin_location => {
        binLocationPromises.push(_getBinLocationData(bin_location))
      })
      const results = await Promise.all(binLocationPromises)
      let i = 0
      for (const binLocationData of results) {
        list[i] = {
          [binLocationData.id]: {
            ...binLocationData,
            businesses: binLocationData.businesses
              .map(business => business.name || '')
              .join(', '),
          },
        }
        i++
      }
      return dispatch({
        type: FETCH_BIN_LOCATION_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_BIN_LOCATION_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_BIN_LOCATION_PROCESSING,
    })
    const binLocationsRef = db.collection(dbCollection)
    const docRef = binLocationsRef.doc(id)
    const binLocationRef = await docRef.get()
    if (binLocationRef.exists) {
      return dispatch({
        type: GET_BIN_LOCATION_SUCCEED,
        payload: {
          record: await _getBinLocationData(binLocationRef),
        },
      })
    } else {
      return dispatch({
        type: GET_BIN_LOCATION_FAILED,
        payload: {
          error: `No such bin_location: ${id}`,
        },
      })
    }
  }
}

const getBinsForBinLocations = id => {
  return async dispatch => {
    dispatch({
      type: GET_BINS_FOR_BIN_LOCATION_PROCESSING,
    })
    const binLocationSnap = await db
      .collection(dbCollection)
      .doc(id)
      .get()
    const binsRef = db.collection(collections.bins)
    const binSnaps = await binsRef
      .where('bin_location', '==', binLocationSnap.ref)
      .get()
    let bins = []
    for (const binSnap of binSnaps.docs) {
      const binData = binSnap.data()
      bins.push({
        name: binData.name,
        value: binSnap.id,
      })
    }
    dispatch({
      type: GET_BINS_FOR_BIN_LOCATION_SUCCEED,
      payload: bins,
    })
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_BIN_LOCATION_PROCESSING,
    })
    const businesses = []
    if (values.businesses.length > 0) {
      for (const biz of values.businesses) {
        const businessRef = db.collection('businesses').doc(biz.value)
        const business = await businessRef.get()
        if (business.exists) {
          businesses.push(business.ref)
        }
      }
    }
    const binLocationData = {
      name: values.name,
      address: values.address || {},
      businesses,
      loc: values.loc
        ? new firebase.firestore.GeoPoint(
            values.loc.latitude,
            values.loc.longitude
          )
        : null,
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection('bin_locations')
          .doc(values.id)
          .set(binLocationData, { merge: true })
      } else {
        await db.collection('bin_locations').add(binLocationData)
      }
      return dispatch({
        type: SAVE_BIN_LOCATION_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: SAVE_BIN_LOCATION_FAILED,
        payload: {
          error: `Error: ${err.message}`,
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_BIN_LOCATION_PROCESSING,
    })
    try {
      const promises = []
      for (const id of ids) {
        promises.push(
          db
            .collection(dbCollection)
            .doc(id)
            .delete()
        )
      }
      await Promise.all(promises)
      return dispatch({
        type: DELETE_BIN_LOCATION_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_BIN_LOCATION_FAILED,
      })
    }
  }
}

export const bin_location = {
  search,
  searchList,
  list,
  get,
  getBinsForBinLocations,
  save,
  remove,
}
