import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'

import { collection_request } from '../../actions'
import CustomTable from '../Table/CustomTable'
import { selects } from '../../constants'

const baseUrl = '/collection-request'

class CollectionRequestList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    query: '',
    orderBy: ['created_at', 'asc'],
    filterBy: {},
    fields: [
      { id: 'bin', label: 'Bin' },
      { id: 'business', label: 'Business' },
      { id: 'amount', label: 'Amount' },
      {
        id: 'paid_status',
        label: 'Paid status',
        select: {
          valueField: 'value',
          textField: 'text',
          data: selects.paid_statuses,
          handleChange: value => {
            this.setState(
              {
                ...this.state,
                filterBy: {
                  ...this.state.filterBy,
                  paid_status: value,
                },
              },
              () => this._getAll()
            )
          },
        },
      },
    ],
  }

  static propTypes = {
    total: PropTypes.number,
    list: PropTypes.array,
    fetch: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    record: PropTypes.object,
    searchText: PropTypes.string,
    errorMessage: PropTypes.string,
    deleted: PropTypes.bool,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search)
    this.setState(
      {
        query: query.searchText || '',
        page: Number(query.page) || 0,
        filterBy: query.filterBy,
      },
      () => {
        this._getAll()
      }
    )
  }
  _getAll(direction = 0) {
    let pagination = {}
    if (direction !== 0 && this.props.list[0]) {
      pagination = {
        direction,
        id:
          direction === -1
            ? Object.keys(this.props.list[0])[0]
            : Object.keys(this.props.list[this.props.list.length - 1])[0],
      }
    }
    this.props.fetch(
      pagination,
      this.state.rowsPerPage,
      this.state.orderBy,
      this.state.filterBy
    )
    let searchParams = {}
    if (this.state.orderBy) searchParams.orderBy = this.state.orderBy
    if (this.state.filterBy) searchParams.filterBy = this.state.filterBy
    const search = qs.stringify(searchParams)
    this.props.history.push({ search })
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getAll()
    })
  }
  handlePaidStatusSelectChange = value => {
    this.setState(
      {
        ...this.state,
        filterBy: {
          ...this.state.filterBy,
          paid_status: value,
        },
      },
      this._getAll
    )
  }
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getAll()
    })
  }
  handleDeleteAction = async id => {
    await this.props.dispatch(collection_request.remove(id))
    this._getAll()
  }
  render() {
    const { list, total, fetching, fetched, history } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push(`${baseUrl}/add`)}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Collection requests'}
          total={total}
          fetching={fetching}
          fetched={fetched}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath={`${baseUrl}/edit/`}
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: `${baseUrl}/edit/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ collection_request }) => ({
  total: collection_request.total,
  list: collection_request.list,
  fetching: collection_request.fetching,
  fetched: collection_request.fetched,
  deleted: collection_request.deleted,
  errorMessage: collection_request.errorMessage,
})

const mapDispatchToProps = { ...collection_request }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionRequestList)
