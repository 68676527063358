import {
  COUNT_BIN_LOCATION_FAILED,
  COUNT_BIN_LOCATION_SUCCEED,
  COUNT_BIN_LOCATION_PROCESSING,
  FETCH_BIN_LOCATION_FAILED,
  FETCH_BIN_LOCATION_SUCCEED,
  FETCH_BIN_LOCATION_PROCESSING,
  GET_BIN_LOCATION_FAILED,
  GET_BIN_LOCATION_SUCCEED,
  GET_BIN_LOCATION_PROCESSING,
  SAVE_BIN_LOCATION_FAILED,
  SAVE_BIN_LOCATION_SUCCEED,
  SAVE_BIN_LOCATION_PROCESSING,
  DELETE_BIN_LOCATION_FAILED,
  DELETE_BIN_LOCATION_SUCCEED,
  DELETE_BIN_LOCATION_PROCESSING,
  ADD_BIN_LOCATION_BUSINESS,
  REMOVE_BIN_LOCATION_BUSINESS,
  UPDATE_BIN_LOCATION_LAT_LNG,
  GET_BINS_FOR_BIN_LOCATION_PROCESSING,
  GET_BINS_FOR_BIN_LOCATION_SUCCEED,
  CLEAR_BIN_LOCATION_FORM,
} from '../constants/bin_location.constants'
import { defaultLatLng } from '../constants'

const initialState = {
  fetching: false,
fetched: false,
  fetchingBins: false,
  fetchedBins: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: null,
  list: [],
  binList: [],
  total: 0,
  record: {
    id: null,
    name: '',
    address: {
      line_1: '',
      line_2: '',
      line_3: '',
      post_town: '',
      postcode: '',
    },
    businesses: [],
    loc: defaultLatLng,
  },
}

const bin_location = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BIN_LOCATION_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_BIN_LOCATION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_BIN_LOCATION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_BIN_LOCATION_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
        errorMessage: action.payload.error,
      }
    case GET_BIN_LOCATION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_BIN_LOCATION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case COUNT_BIN_LOCATION_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_BIN_LOCATION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_BIN_LOCATION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_BIN_LOCATION_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_BIN_LOCATION_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_BIN_LOCATION_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_BIN_LOCATION_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_BIN_LOCATION_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_BIN_LOCATION_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case ADD_BIN_LOCATION_BUSINESS:
      return {
        ...state,
        record: {
          ...state.record,
          businesses: state.record.bins.push(action.payload),
        },
      }
    case REMOVE_BIN_LOCATION_BUSINESS:
      return {
        ...state,
        record: {
          ...state.record,
          businesses: state.record.businesses.filter(
            business => business.id !== action.payload
          ),
        },
      }
    case UPDATE_BIN_LOCATION_LAT_LNG:
      return {
        ...state,
        record: {
          ...state.record,
          loc: {
            latitude: action.payload[1],
            longitude: action.payload[0],
          },
        },
      }
    case GET_BINS_FOR_BIN_LOCATION_PROCESSING:
      return {
        ...state,
        fetchingBins: true,
        fetchedBins: false,
        binList: [],
      }
    case GET_BINS_FOR_BIN_LOCATION_SUCCEED:
      return {
        ...state,
        fetchingBins: false,
        fetchedBins: true,
        binList: action.payload,
      }
    case CLEAR_BIN_LOCATION_FORM:
      return {
        ...state,
        record: initialState.record,
        binList: [],
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default bin_location
