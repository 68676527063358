import {
  COUNT_QUOTE_REQUEST_FAILED,
  COUNT_QUOTE_REQUEST_SUCCEED,
  COUNT_QUOTE_REQUEST_PROCESSING,
  FETCH_QUOTE_REQUEST_FAILED,
  FETCH_QUOTE_REQUEST_SUCCEED,
  FETCH_QUOTE_REQUEST_PROCESSING,
  GET_QUOTE_REQUEST_FAILED,
  GET_QUOTE_REQUEST_SUCCEED,
  GET_QUOTE_REQUEST_PROCESSING,
  SAVE_QUOTE_REQUEST_FAILED,
  SAVE_QUOTE_REQUEST_SUCCEED,
  SAVE_QUOTE_REQUEST_PROCESSING,
  DELETE_QUOTE_REQUEST_FAILED,
  DELETE_QUOTE_REQUEST_SUCCEED,
  DELETE_QUOTE_REQUEST_PROCESSING,
  CLEAR_FORM,
} from '../constants/quote_request.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  list: [],
  total: 0,
  record: {
    business_size: '',
    business_type: '',
    email: '',
    other_business_type: '',
    postcode: '',
    status: '',
    tel: '',
  },
}

const quote_request = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUOTE_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case FETCH_QUOTE_REQUEST_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_QUOTE_REQUEST_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_QUOTE_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
      }
    case GET_QUOTE_REQUEST_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_QUOTE_REQUEST_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case COUNT_QUOTE_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case COUNT_QUOTE_REQUEST_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_QUOTE_REQUEST_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_QUOTE_REQUEST_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
      }
    case SAVE_QUOTE_REQUEST_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_QUOTE_REQUEST_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_QUOTE_REQUEST_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
      }
    case DELETE_QUOTE_REQUEST_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_QUOTE_REQUEST_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case CLEAR_FORM:
      return {
        ...state,
        record: initialState.record,
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    default:
      return state
  }
}

export default quote_request
