import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { Paper, withStyles, Button, IconButton } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { deburr } from 'lodash'
import DownshiftInput from './DownshiftInput'
import DownshiftSuggestion from './DownshiftSuggestion'

class DownshiftSingle extends React.Component {
  state = {
    isEditing: false,
    suggestions: [],
  }

  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    getSuggestions: PropTypes.func.isRequired,
    itemToString: PropTypes.func,
    handleClickLink: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    classes: PropTypes.object,
  }

  static defaultProps = {
    itemToString: i => {
      return i == null ? '' : i.name ? i.name : ''
    },
  }

  handleChange = value => {
    this.setState({ isEditing: false })
    this.props.input.onChange(value)
  }

  handleInputValueChanged = async value => {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) return []
    const suggestions = await this.props.getSuggestions(inputValue)
    this.setState({
      suggestions,
    })
  }

  handleClickEdit = () => {
    this.setState({
      isEditing: true,
    })
  }

  render() {
    const {
      input,
      meta,
      label,
      placeholder,
      itemToString,
      handleClickLink,
      classes,
    } = this.props

    return this.state.isEditing || !input.value.value ? (
      <Downshift
        id="downshift-simple"
        onChange={this.handleChange}
        initialInputValue={input.value.name}
        initialSelectedItem={input.value}
        onInputValueChange={this.handleInputValueChanged}
        itemToString={itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
        }) => (
          <div className={classes.container}>
            <DownshiftInput
              fullWidth={true}
              classes={classes}
              input={getInputProps({
                placeholder,
              })}
              label={label}
              meta={meta}
              margin={'normal'}
            />
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {this.state.suggestions.map((suggestion, index) => (
                    <DownshiftSuggestion
                      key={index}
                      suggestion={suggestion}
                      index={index}
                      itemProps={getItemProps({
                        item: suggestion,
                        key: index,
                      })}
                      highlightedIndex={highlightedIndex}
                    />
                  ))}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    ) : (
      <div className={classes.root}>
        <Button onClick={() => handleClickLink(input.value.value)}>
          {input.value.name}
        </Button>
        <IconButton aria-label="Edit" onClick={this.handleClickEdit}>
          <EditIcon />
        </IconButton>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
})

export default withStyles(styles)(DownshiftSingle)
