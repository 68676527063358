import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class InfoPopup extends PureComponent {
  render() {
    const { info } = this.props
    const displayName = `${info.name}`

    return (
      <div>
        <div>
          {displayName} |{' '}
        </div>
        {/* <img width={240} alt={info.alt} src={info.image} /> */}
      </div>
    )
  }
}

InfoPopup.propTypes = {
  info: PropTypes.object
}

export { InfoPopup }
