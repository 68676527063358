import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import './assets/css/material-dashboard-react.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
} from '@material-ui/core'
import registerServiceWorker from './registerServiceWorker'
import indexRoutes from './routes/index.jsx'
import binitTheme from './variables/theme'

import 'mapbox-gl/dist/mapbox-gl.css'

const hist = createBrowserHistory()

const theme = createMuiTheme(binitTheme)

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router
            basename={process.env.REACT_APP_ROUTER_BASENAME}
            history={hist}
          >
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                )
              })}
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </React.Fragment>,
  document.getElementById('root')
)

registerServiceWorker()
