import {
  COUNT_USER_FAILED,
  COUNT_USER_SUCCEED,
  COUNT_USER_PROCESSING,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEED,
  FETCH_USER_PROCESSING,
  GET_USER_FAILED,
  GET_USER_SUCCEED,
  GET_USER_PROCESSING,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCEED,
  SAVE_USER_PROCESSING,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCEED,
  DELETE_USER_PROCESSING,
  CLEAR_FORM,
  TOGGLE_SIDEBAR,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  list: [],
  total: 0,
  record: {
    email: '',
    uid: '',
    displayName: '',
    role: 'customer',
    notifications: {
      email: {
        oneOffRequest: true,
        collectionRequest: true,
        problem: true,
        binCollection: true,
        binbagRequest: true,
      },
    },
    business: {
      name: '',
      value: '',
    },
  },
  sidebarOpen: false,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case FETCH_USER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_USER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case GET_USER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case GET_USER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_USER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        saved: false,
        saving: false,
        deleted: false,
        deleting: false,
      }
    case COUNT_USER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      }
    case COUNT_USER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_USER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_USER_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
      }
    case SAVE_USER_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_USER_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_USER_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload
      }
    case DELETE_USER_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_USER_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case CLEAR_FORM:
      return {
        ...state,
        record: initialState.record,
        saving: false,
        saved: false,
        deleting: false,
        deleted: false,
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      }
    default:
      return state
  }
}

export default user
