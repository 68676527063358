import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Typography,
  Tooltip,
  IconButton,
  withStyles,
  Toolbar,
  colors,
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  MergeType as MergeTypeIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons'
import CustomInput from '../CustomInput'

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: colors.grey[800],
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  searchInput: {
    flex: '1 1 100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginLeft: '20px',
  },
  actions: {
    color: theme.palette.primary.light,
  },
  title: {
    flex: '0 0 auto',
  },
  extraSearchElements: {
    marginTop: '43px',
    margin: '0 20px',
  },
})

class EnhancedTableToolbar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.array,
    handleSearch: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickMerge: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    extraSearchElements: PropTypes.object,
    count: PropTypes.number,
    enableDuplicate: PropTypes.bool,
  }

  render() {
    const {
      numSelected,
      classes,
      title,
      actions,
      handleSearch,
      handleClickDelete,
      handleClickMerge,
      handleClickDuplicate,
      extraSearchElements,
      count,
      enableDuplicate,
    } = this.props

    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <div>
              <Typography variant="h6">{title}</Typography>
              {count ? (
                <Typography variant="subtitle1" gutterBottom>
                  ({count})
                </Typography>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        {/* <div className={classes.spacer} /> */}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              className={classes.actions}
              onClick={handleClickDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : handleSearch ? (
          <div className={classes.searchInput}>
            <div className={classes.extraSearchElements}>
              {extraSearchElements}
            </div>
            <CustomInput
              formControlProps={{
                className: classes.top + ' ' + classes.search,
              }}
              inputProps={{
                placeholder: 'Search',
                inputProps: {
                  'aria-label': 'Search',
                },
                onChange: handleSearch,
              }}
            />
          </div>
        ) : (
          ''
        )}
        {numSelected > 1 && actions.find(action => action.label === 'merge') ? (
          <Tooltip title="Merge">
            <IconButton
              aria-label="Merge"
              className={classes.actions}
              onClick={handleClickMerge()}
            >
              <MergeTypeIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
        {numSelected === 1 && enableDuplicate ? (
          <Tooltip title="Duplicate">
            <IconButton
              aria-label="Duplicate"
              className={classes.actions}
              onClick={handleClickDuplicate()}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    )
  }
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar)
