import React from 'react'
import PropTypes from 'prop-types'
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'

const BasicSelectField = ({
  meta: { touched, error, invalid },
  input: { ...inputProps },
  label,
  data,
  valueField = 'foo',
  textField,
  ...props
}) => (
  <FormControl error={touched && invalid} fullWidth>
    <InputLabel htmlFor={inputProps.name}>{label}</InputLabel>
    <Select
      value={inputProps.value}
      onChange={inputProps.onChange}
      inputProps={{
        name: valueField,
        id: `${valueField}-simple`,
      }}
      fullWidth
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {data &&
        data.map((datum, i) => (
          <MenuItem value={datum[valueField]} key={i}>
            {datum[textField]}
          </MenuItem>
        ))}
    </Select>
    <FormHelperText>{error}</FormHelperText>
  </FormControl>
)

BasicSelectField.propTypes = {
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
  valueField: PropTypes.string,
  textField: PropTypes.string.isRequired,
  classes: PropTypes.object,
}

/* const styles = theme => ({
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
}) */

export default BasicSelectField
