import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'

class DownshiftSuggestion extends Component {
  static propTypes = {
    suggestion: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    itemProps: PropTypes.object.isRequired,
    highlightedIndex: PropTypes.number,
    selectedItem: PropTypes.object,
  }

  render() {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = this.props
    const isHighlighted = highlightedIndex === index
    const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1

    return (
      <MenuItem
        {...itemProps}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.name}
      </MenuItem>
    )
  }
}

export default DownshiftSuggestion
