import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, Typography, withStyles } from '@material-ui/core'

import footerStyle from '../../variables/styles/footerStyle'

import packageJSON from '../../../package.json'

function Footer({ ...props }) {
  const { classes } = props
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://dashboard.gardencompass.com/"
                className={classes.block}
                target="_blank"
                rel="noopener noreferrer"
              >
                firebase Dashboard
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{' '}
            <a href="http://www.creative-tim.com" className={classes.a}>
              Binit
            </a>
            , made with ❤️
            <Typography variant="overline" gutterBottom>
              v{packageJSON.version}
            </Typography>
          </span>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(footerStyle)(Footer)
