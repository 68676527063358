import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'rc-calendar'
import enGB from 'rc-calendar/lib/locale/en_GB'
import 'moment/locale/en-gb'
import 'rc-calendar/assets/index.css'
import moment from 'moment'

class CustomCalendar extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    disabledDate: PropTypes.func,
  }

  static defaultProps = {
    futureDates: date => {
      if (!date) {
        // allow empty select
        return false
      }
      const now = moment()
      return date.valueOf() < now.valueOf() // cannot select days before today
    },
  }

  onCalendarChange = date => {
    this.props.input.onChange(date.toDate())
  }

  render() {
    const { input, disabledDate } = this.props
    const value = input.value ? moment(input.value) : moment()
    return (
      <div>
        <Calendar
          value={value}
          onChange={this.onCalendarChange}
          format="DD-MM-YYYY"
          locale={enGB}
          disabledDate={disabledDate}
        />
      </div>
    )
  }
}

export default CustomCalendar
