import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { Header, Footer, Sidebar } from '../components'
import appRoutes from '../routes/app'
import appStyle from '../variables/styles/appStyle.jsx'
import logo from '../assets/img/binit-logo-white.svg'
import DashboardPage from '../components/Home'
import LoginPage from '../components/auth/Login'
import RequestPasswordReset from '../components/auth/RequestPasswordReset'
import SignupPage from '../components/auth/Signup'
import qs from 'query-string'
import { auth } from '../actions'
import { allowedRoutesRegexp } from '../constants'

const RouteWithSubRoutes = route => (
  <div>
    <Route exact path={route.path} component={route.component} />
    {route.routes.map(sub => {
      return (
        <Route
          exact
          path={sub.path}
          component={sub.component}
          key={sub.action}
        />
      )
    })}
  </div>
)

const switchRoutes = (
  <Switch>
    <Route path="/login" component={LoginPage} key="login" />
    <Route path="/signup" component={SignupPage} key="signup" />
    <Route
      path="/request-password-reset"
      component={RequestPasswordReset}
      key="request-password-signup"
    />

    <Route path="/dashboard" component={DashboardPage} key="dashboard" />
    {appRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />
      if (prop.routes !== undefined) {
        return <RouteWithSubRoutes key={key} {...prop} />
      } else {
        return <Route path={prop.path} component={prop.component} key={key} />
      }
    })}
  </Switch>
)
class App extends React.Component {
  state = {
    mobileOpen: false
  }
  constructor(props) {
    super(props)
    this.mainPanelRef = React.createRef()
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.props.dispatch({
          type: 'LOGIN_SUCCESS'
        })
        this.correctRoute()
      } else {
        this.props.dispatch({
          type: 'LOGOUT'
        })
        this.correctRoute()
      }
    })
  }
  correctRoute() {
    if (this.props.isLoggedIn) {
      if (this.props.location.pathname === '/login') {
        if (this.props.location.search.length > 0) {
          const search = qs.parse(this.props.location.search)
          if (search.redirectAfterLogin)
            this.props.history.push(search.redirectAfterLogin)
          else this.props.history.push('/dashboard')
        } else this.props.history.push('/dashboard')
      }
      // this.refs.mainPanel.scrollTop = 0
    } else if (!this.props.location.pathname.match(allowedRoutesRegexp)) {
      // append the current location so we can go back after login
      const search = { redirectAfterLogin: this.props.location.pathname }
      this.props.history.push(`/login?${qs.stringify(search)}`)
    }
  }
  render() {
    const { classes, ...rest } = this.props
    return (
      <div className={classes.wrapper}>
        {this.props.isLoggedIn ? (
          <div>
            <Sidebar
              routes={appRoutes}
              logoText={'Creative Tim'}
              logo={logo}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              {...rest}
            />
            <div className={classes.mainPanel} ref={this.mainPanelRef}>
              <Header
                routes={appRoutes}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
              <Footer />
            </div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}
function mapStateToProps(state) {
  return {
    userId: state.auth.userId,
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default withStyles(appStyle)(connect(mapStateToProps)(App))
