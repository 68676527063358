import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CustomSnackbar from '../CustomSnackbar'
import logo from '../../assets/img/binit-logo-grey.svg'
import { auth } from '../../actions'
import RegularCard from '../Cards/RegularCard.js'
import { Grid } from '@material-ui/core'

const classes = {
  paddingTop: '56.25%'
}

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
      showMessage: ''
    }
  }

  submit = (values) => {
    this.props.dispatch(auth.signup(values))
  }

  render() {
    const { error, errorMessage } = this.props
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={error}
          message={errorMessage}
          handleClose={this.handleCloseError}
        />
        <Grid container justify="center">
          <Grid xs={4} item>
            <RegularCard
              cardTitle="Binit CMS"
              cardSubtitle="Sign up"
              classes={classes}
              mediaUrl={logo}
              mediaTitle="Binit"
              content={''}
              footer={''}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

Signup.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  userId: state.auth.userId,
  token: state.auth.token,
  error: state.auth.error,
  errorMessage: state.auth.errorMessage
})

export default connect(mapStateToProps)(Signup)
