export const COUNT_BIN_PROCESSING = 'COUNT_BIN_PROCESSING'
export const COUNT_BIN_SUCCEED = 'COUNT_BIN_SUCCEED'
export const COUNT_BIN_FAILED = 'COUNT_BIN_FAILED'
export const FETCH_BIN_PROCESSING = 'FETCH_BIN_PROCESSING'
export const FETCH_BIN_SUCCEED = 'FETCH_BIN_SUCCEED'
export const FETCH_BIN_FAILED = 'FETCH_BIN_FAILED'
export const GET_BIN_PROCESSING = 'GET_BIN_PROCESSING'
export const GET_BIN_SUCCEED = 'GET_BIN_SUCCEED'
export const GET_BIN_FAILED = 'GET_BIN_FAILED'
export const FETCH_BIN_GENUS_PROCESSING = 'FETCH_BIN_GENUS_PROCESSING'
export const FETCH_BIN_GENUS_SUCCEED = 'FETCH_BIN_GENUS_SUCCEED'
export const FETCH_BIN_GENUS_FAILED = 'FETCH_BIN_GENUS_FAILED'
export const SAVE_BIN_PROCESSING = 'SAVE_BIN_PROCESSING'
export const SAVE_BIN_SUCCEED = 'SAVE_BIN_SUCCEED'
export const SAVE_BIN_FAILED = 'SAVE_BIN_FAILED'
export const DELETE_BIN_PROCESSING = 'DELETE_BIN_PROCESSING'
export const DELETE_BIN_SUCCEED = 'DELETE_BIN_SUCCEED'
export const DELETE_BIN_FAILED = 'DELETE_BIN_FAILED'
export const GET_BIN_OBSERVATIONS_FAILED = 'GET_BIN_OBSERVATIONS_FAILED'
export const GET_BIN_OBSERVATIONS_SUCCEED = 'GET_BIN_OBSERVATIONS_SUCCEED'
export const GET_BIN_OBSERVATIONS_PROCESSING = 'GET_BIN_OBSERVATIONS_PROCESSING'
export const CLEAR_FORM = 'CLEAR_FORM'
