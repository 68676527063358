import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import logo from '../assets/img/binit-logo-grey.svg'

const spaceStyle = {
  height: 400
}

const logoStyle = {
  width: 200
}

const Home = () => (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="app-logo" style={logoStyle} alt="logo" />
      <h1 className="App-title">Dashboard</h1>
    </header>
    <div style={spaceStyle}></div>
  </div>
)

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,	
	userId: state.auth.userId,
	token: state.auth.token
})

export default withRouter(connect(mapStateToProps)(Home))
