import React from 'react'
import {
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'

class CustomSwitch extends React.Component {
  render() {
    const { ...inputProps } = this.props.input

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...inputProps}
              checked={inputProps.value}
              value={inputProps.name}
              {...this.props}
            />
          }
          label={this.props.label}
        />
      </FormGroup>
    )
  }
}

export default CustomSwitch
