import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

class DownshiftInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object.isRequired,
    classes: PropTypes.object,
  }

  render() {
    const {
      input: { ...inputProps },
      classes,
      meta: { touched, invalid, error },
      ...props
    } = this.props

    return (
      <TextField
        {...inputProps}
        error={touched && invalid}
        helperText={error}
        {...props}
      />
    )
  }
}

export default DownshiftInput
