export const COUNT_BINBAG_REQUEST_PROCESSING = 'COUNT_BINBAG_REQUEST_PROCESSING'
export const COUNT_BINBAG_REQUEST_SUCCEED = 'COUNT_BINBAG_REQUEST_SUCCEED'
export const COUNT_BINBAG_REQUEST_FAILED = 'COUNT_BINBAG_REQUEST_FAILED'
export const FETCH_BINBAG_REQUEST_PROCESSING = 'FETCH_BINBAG_REQUEST_PROCESSING'
export const FETCH_BINBAG_REQUEST_SUCCEED = 'FETCH_BINBAG_REQUEST_SUCCEED'
export const FETCH_BINBAG_REQUEST_FAILED = 'FETCH_BINBAG_REQUEST_FAILED'
export const GET_BINBAG_REQUEST_PROCESSING = 'GET_BINBAG_REQUEST_PROCESSING'
export const GET_BINBAG_REQUEST_SUCCEED = 'GET_BINBAG_REQUEST_SUCCEED'
export const GET_BINBAG_REQUEST_FAILED = 'GET_BINBAG_REQUEST_FAILED'
export const SAVE_BINBAG_REQUEST_PROCESSING = 'SAVE_BINBAG_REQUEST_PROCESSING'
export const SAVE_BINBAG_REQUEST_SUCCEED = 'SAVE_BINBAG_REQUEST_SUCCEED'
export const SAVE_BINBAG_REQUEST_FAILED = 'SAVE_BINBAG_REQUEST_FAILED'
export const DELETE_BINBAG_REQUEST_PROCESSING =
  'DELETE_BINBAG_REQUEST_PROCESSING'
export const DELETE_BINBAG_REQUEST_SUCCEED = 'DELETE_BINBAG_REQUEST_SUCCEED'
export const DELETE_BINBAG_REQUEST_FAILED = 'DELETE_BINBAG_REQUEST_FAILED'
export const CLEAR_FORM = 'CLEAR_FORM'
