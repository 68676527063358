import React from 'react'
import { withStyles } from '@material-ui/core'
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions
} from '@material-ui/core'
import PropTypes from 'prop-types'

import regularCardStyle from '../../variables/styles/regularCardStyle'

function RegularCard({ ...props }) {
  const {
    classes,
    headerColor,
    mediaUrl,
    mediaTitle,
    cardTitle,
    cardSubtitle,
    cardAction,
    content,
    footer
  } = props
  return (
    <Card className={classes.card}>
      {mediaUrl !== undefined ? (
        <CardMedia
          className={classes.media}
          image={mediaUrl}
          title={mediaTitle}
        />
      ) : null}
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
          subheader: classes.cardSubheader
        }}
        style={{ color: headerColor }}
        title={cardTitle}
        subheader={cardSubtitle}
        action={cardAction}
      />
      <CardContent>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      ) : null}
    </Card>
  )
}

RegularCard.defaultProps = {
  headerColor: 'primary'
}

RegularCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'orange',
    'green',
    'red',
    'blue',
    'purple'
  ]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  cardAction: PropTypes.node,
  content: PropTypes.node,
  mediaUrl: PropTypes.string,
  mediaTitle: PropTypes.string,
  footer: PropTypes.node
}

const styles = theme => ({
  ...regularCardStyle,
  cardHeader: {
    ...regularCardStyle.cardHeader,
    backgroundColor: theme.palette.primary.main
  },
  cardTitle: {
    color: theme.palette.primary.contrastText
  },
  cardSubheader: {
    color: theme.palette.primary.contrastText
  }
})

export default withStyles(styles)(RegularCard)
