import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon
} from '@material-ui/icons'
import { Button } from '@material-ui/core'

import { binbag_roll } from '../../actions'
import CustomTable from '../Table/CustomTable'

const baseUrl = '/binbag-roll'

class BinbagRollList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      query: '',
      filterBinGenus: '',
      filterBinGenera: [],
      fields: [
        {
          id: 'quantity',
          label: 'Quantity'
        }
      ]
    }
  }
  componentDidMount() {
    const query = qs.parse(this.props.location.search)
    this.setState(
      {
        query: query.searchText || '',
        page: query.page || 0
      },
      () => {
        this._getAll()
      }
    )
  }
  _getAll() {
    const searchText = this.state.query || ''
    const page = this.state.page || 0
    this.props.dispatch(
      binbag_roll.list(
        page,
        this.state.rowsPerPage,
        searchText,
        this.state.filterBinGenus
      )
    )
    const search = qs.stringify({
      searchText,
      page
    })
    this.props.history.push({ search })
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getAll()
    })
  }
  handleSelectChange = event => {
    this.setState({
      filterBinGenus: event.target.value
    })
    this.handleSearch(this.state.query)
  }
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getAll()
    })
  }
  handleDeleteAction = async id => {
    await this.props.dispatch(binbag_roll.remove(id))
    this._getAll()
  }
  render() {
    const { list, total, fetching, fetched, history } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push(`${baseUrl}/add`)}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Binbag rolls'}
          total={total}
          fetching={fetching}
          fetched={fetched}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath={`${baseUrl}/edit/`}
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: `${baseUrl}/edit/`,
              has_id: true,
              color: 'primary'
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary'
            }
          ]}
        />
      </div>
    )
  }
}

BinbagRollList.propTypes = {
  total: PropTypes.number,
  list: PropTypes.array,
  fetching: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  record: PropTypes.object,
  searchText: PropTypes.string,
  errorMessage: PropTypes.string,
  deleted: PropTypes.bool,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  total: state.binbag_roll.total,
  list: state.binbag_roll.list,
  fetching: state.binbag_roll.fetching,
  fetched: state.binbag_roll.fetched,
  deleted: state.binbag_roll.deleted,
  errorMessage: state.binbag_roll.errorMessage
})

export default connect(mapStateToProps)(BinbagRollList)
