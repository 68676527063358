import {
  FETCH_QUOTE_REQUEST_FAILED,
  FETCH_QUOTE_REQUEST_SUCCEED,
  FETCH_QUOTE_REQUEST_PROCESSING,
  GET_QUOTE_REQUEST_FAILED,
  GET_QUOTE_REQUEST_SUCCEED,
  GET_QUOTE_REQUEST_PROCESSING,
  SAVE_QUOTE_REQUEST_FAILED,
  SAVE_QUOTE_REQUEST_SUCCEED,
  SAVE_QUOTE_REQUEST_PROCESSING,
  DELETE_QUOTE_REQUEST_FAILED,
  DELETE_QUOTE_REQUEST_SUCCEED,
  DELETE_QUOTE_REQUEST_PROCESSING,
} from '../../constants'
import { db } from '../firebase'
import { paginate } from '../../utils'

const dbCollection = 'quote_requests'

const list = (
  pagination,
  rowsPerPage = 10,
  orderBy = ['created_at', 'asc'],
  filterBy = {}
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_QUOTE_REQUEST_PROCESSING,
    })
    try {
      const collectionRef = db.collection(dbCollection)
      // set up order by
      const collectionQuery = collectionRef.orderBy(orderBy[0], orderBy[1])
      // set up pagination
      let paginateQuery = await paginate(
        collectionQuery,
        collectionRef,
        pagination
      )
      // set up filter by
      for (const fieldPath in filterBy) {
        if (filterBy.hasOwnProperty(fieldPath)) {
          const value = filterBy[fieldPath]
          if (value) paginateQuery = paginateQuery.where(fieldPath, '==', value)
        }
      }
      // do the limit
      const limitQuery = paginateQuery.limit(rowsPerPage)
      const docRefs = await limitQuery.get()
      const list = []
      let i = 0
      docRefs.forEach(quoteRequest => {
        list[i] = {
          [quoteRequest.id]: {
            ...quoteRequest.data(),
          },
        }
        i++
      })
      return dispatch({
        type: FETCH_QUOTE_REQUEST_SUCCEED,
        payload: { list },
      })
    } catch (err) {
      return dispatch({
        type: FETCH_QUOTE_REQUEST_FAILED,
        payload: {
          error: 'Error: ' + err.code + ' ' + err.message,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_QUOTE_REQUEST_PROCESSING,
    })
    const collectionRef = db.collection(dbCollection)
    const docRef = collectionRef.doc(id)
    const quoteRequestSnap = await docRef.get()
    if (quoteRequestSnap.exists) {
      const quoteRequestData = quoteRequestSnap.data()

      return dispatch({
        type: GET_QUOTE_REQUEST_SUCCEED,
        payload: {
          record: {
            ...quoteRequestData,
            id: quoteRequestSnap.id,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_QUOTE_REQUEST_FAILED,
        payload: {
          error: `No such collection request: ${id}`,
        },
      })
    }
  }
}

const save = values => {
  return async dispatch => {
    dispatch({
      type: SAVE_QUOTE_REQUEST_PROCESSING,
    })

    const data = {
      ...values,
    }
    try {
      if (values.id !== undefined && values.id !== null && values.id !== '') {
        await db
          .collection(dbCollection)
          .doc(values.id)
          .set(data, { merge: true })
      } else {
        await db.collection(dbCollection).add(data)
      }
      return dispatch({
        type: SAVE_QUOTE_REQUEST_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: SAVE_QUOTE_REQUEST_FAILED,
        payload: {
          error: 'Error: Saving quote request field',
        },
      })
    }
  }
}

const remove = ids => {
  return async dispatch => {
    dispatch({
      type: DELETE_QUOTE_REQUEST_PROCESSING,
    })
    try {
      const promises = []
      for (const id of ids) {
        promises.push(
          db
            .collection(dbCollection)
            .doc(id)
            .delete()
        )
      }
      await Promise.all(promises)
      return dispatch({
        type: DELETE_QUOTE_REQUEST_SUCCEED,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_QUOTE_REQUEST_FAILED,
      })
    }
  }
}

export const quote_request = {
  list,
  get,
  save,
  remove,
}
